 <template>
	<div>
		<s-toolbar
			class="elevation-0"
			dark
			:color="'#b9b9b9'"
			close
			@close="exit()"
			height="40">
			<h2>MUESTREO LP - {{ items.RcfID }}</h2>
			<v-spacer></v-spacer>
		</s-toolbar>
		<v-col>
			<v-card class="pt-0 elevation-0 pb-0" elevation="0">
				<v-row>
					<v-col lg="12" cols="12">
						<v-alert
							v-model="alertInfoSampling"
							:color="'#9EB8F6'"
							dark
							dense
							shaped
							style="position:;border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: -12px; margin-right: 5;}"
						>
							<div>
								<v-row justify="center">
									<v-col lg="6" cols="6">
										<b>Puerta:</b>
										{{ guideSelected.TypeDoorName }}
									</v-col>
									<v-col lg="6" cols="6">
										<b>Fundo:</b>
										{{ guideSelected.FagName }}
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<b>{{this.typeCultiveName}}</b>
									</v-col>
								</v-row>
							</div>
						</v-alert>
					</v-col>
					<!-- {{"Puerta: " + guideSelected.TypeDoorName + " Fundo: " + guideSelected.FagName}} -->
				</v-row>
				<v-row justify="center" class="mt-0">
					<v-col  cols="6" xs="12" sm="6" md="6" lg="3">
						<s-select
							:items="itemsNumberGuide"
							full
							autocomplete
							item-value="RcfID"
							item-text="RcfNumberHarvestGuide"
							label="Seleccione Nro. Guia de cosecha"
							 
							return-object
							@input="inputNumberGuide($event)"
							:slotTmp="true"
							style="margin-top: -20px"
						>
							<template v-slot:item="data">
								{{ data.item.RcfNumberHarvestGuide }}
								<!-- {{data.item}} -->
								<v-chip
									v-if="data.item.TypeDoorName != null"
									x-small
									color="warning"
								>
									{{ data.item.TypeDoorName }}
								</v-chip>
								<v-chip
									v-if="data.item.Sampling == 1"
									x-small
									:color="data.item.Sampling >= 1? 'info': 'warning'"
									dark
								>
									{{ data.item.Sampling >= 1? "Muestrado": "Sin Muestra" }}
								</v-chip>

								<v-chip
									v-if="data.item.Sampling !== 1"
									x-small
									:color="data.item.SampleBrix >= 1? 'info': 'error'"
									dark
								>
									{{ data.item.SampleBrix >= 1? "M-Brix": "No Brix" }}
								</v-chip>
								<v-chip
									v-if="data.item.Sampling !== 1"
									x-small
									:color="data.item.SampleDamage >= 1? 'info': 'error'"
									dark
								>
									{{ data.item.SampleDamage >= 1? "M-Daños": "No Daños" }}
								</v-chip>
							</template>
						</s-select>
					</v-col>
					<!-- <h2 class="pt-2 pb-0 pl-3" style="color: red">Capataz:</h2> -->
					<v-col cols="6" xs="12" sm="6" md="6"  lg="3" style="margin-top: -20px">
						<s-select
							:items="itemsGang"
							full
							autocomplete
							item-value="RcfGngID"
							item-text="FrmName"
							label="Seleccione Capataz"
							return-object
							 
							@input="inputForeman($event)"
						 	:slotTmp="true"
						>
							<template v-slot:item="data">
								{{ data.item.FrmName + " " }}
								<v-chip
									x-small
									:color="data.item.SampleBrix >= 1? 'info': 'error'"
									dark
								>
									{{ data.item.SampleBrix >= 1? "M-Brix": "No Brix" }}
								</v-chip>
								<v-chip
									x-small
									:color="data.item.SampleDamage >= 1? 'info': 'error'"
									dark
								>
									{{ data.item.SampleDamage >= 1? "M-Daños": "No Daños" }}
								</v-chip>
								<v-chip x-small color="" dark>
									{{ data.item.FrmQuantityReal }}
								</v-chip>
							</template>
						</s-select>
						<span v-if="objForeman">
							Cant. Jabas Recepcionadas:
							<b style="font-size: 16px">{{ objForeman.FrmQuantityReal }}</b>
						</span>
					</v-col>					
				</v-row>
			
			</v-card>

			<v-card v-if="!showDamageIf" elevation="0" class="mt-6">
				<v-row justify="center">
					<v-col  cols="6">
						<p > Para empezar el muestreo, Seleccione un Capataz ...</p>
						  
					</v-col>
				</v-row>			
			</v-card>
			<v-card v-if="showDamageIf" elevation="0"> 		
				<v-row justify="center" class="mt-0" v-if="this.$fun.hasSpecialPermission('samplingdamage')">
					<v-col cols="3">						 
						<v-switch
						name="SquAwning"
						@click ="saveswitchv"
						label="Toldo"
						v-model="SquAwning"></v-switch>
					</v-col>
					<v-col cols="3">						 
						<v-switch
							name="SquCleaning"
							@click ="saveswitchv"
							label="Limpieza"
							v-model="SquCleaning"></v-switch>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="pt-0 pb-3"><v-divider></v-divider></v-col>
				</v-row>		
				<v-row>
					<v-col
						v-if="this.$fun.hasSpecialPermission('samplingbrix')"
						class="pt-0">
						<!-- <v-container> -->
						<h3>Muestreo De Fruta</h3>
						<v-col style="margin-top: -20px">
							<v-row justify="center">
								<v-col cols="4" >
									<s-text
										v-model="BcHead.BrxNumberJabas"
										ref="FocuesNumberJabas"
										label="Unidades Muestreadas"
										readonly
									></s-text>
								</v-col>
								<v-col cols="4" 
									>
									<!--<s-select-definition
										label="Unidad de Muestreo"
										v-model="BcHead.UnitMeasurement"
										:def="1286"
									></s-select-definition>-->
								</v-col>
							</v-row>
						</v-col>			
						<v-col>
							<v-row class="pt-3 pr-3 pl-3">
								<h3>BRIX / COLOR</h3>
								<v-spacer></v-spacer>								
							</v-row>
						</v-col>

						<v-col cols="12" lg="12" md="12" >
							<v-card 
								class="pl-5 mb-2 pt-5 "									 
								outlined >	 									
									<v-row v-if="TypeCultive == 1" class="p-0 m-0"> 
										<v-col cols="3" >
											<s-text 
												decimal
												v-model="itemBc.BrdBrixValue"
												label="Brix"/>
										</v-col>
										<v-col cols="3">
											<s-select
												label="Color"
												clearable
												:items="itemColorVariety"
												item-text="BxcColorDescription"
												item-value="BxcID"
												v-model="itemBc.BxcID"
												ref="colorSelect"
											></s-select>
										</v-col>
										<v-col cols="3">
											<v-btn
												class="mr-3 elevation-5"
												x-small
												fab
												color="primary"
												@click="saveItemBc(1)"
												><i class="fas fa-plus"></i
											></v-btn>
										</v-col>
									</v-row>
									<v-row v-if="TypeCultive != 1">
										<v-col cols="3">											 
											<s-text 
												decimal
												v-model="itemBc.BrxWeightBegin"
												label="P. Inicial"
												@input="calcPercent($event)"/>
										</v-col>
										<v-col cols="3">
											<s-text 
												decimal
												v-model="itemBc.BrxWeightEnd"
												label="P. Fin"
												@input="calcPercent($event)"/>
										</v-col>
										<v-col cols="3">
											<s-text 
												decimal
												v-model="itemBc.BrxPercentage"
												label="Porcentaje"
												readonly/>
										</v-col>
										<v-col cols="3">
											<v-btn
												class="mr-3 elevation-5"
												x-small
												fab
												color="primary"
												@click="saveItemBc(2)"
												><i class="fas fa-plus"></i
											></v-btn>
										</v-col>
									</v-row>
 							 <v-row class="p-0 m-0">
										<v-col  class="p-0 m-0">
												<p  class="pb-0 mb-0"> Elegir Calibre </p>																				
							<!--	<s-select-generic										 
									v-model="itemBc.TypeCaliber"			
									:items="itemsCaliber"	
									itemtext="DedDescription"		
									itemvalue="DedValue"				 	 									 
									radio
									row 
									/>
									 element.DedDescription;
								element.TypeCaliber = element.DedValue;
										-->	
										
									<v-radio-group row v-model="itemBc.TypeCaliber">
									<v-radio
										class="h1"
										v-for="n in itemsCaliber"
										:key="n.DedValue"
										:label="`${n.DedDescription}`"
										:value="n.DedValue"
									></v-radio>
									</v-radio-group> 								 
										</v-col>
									</v-row>									
							</v-card>
							<div>								
								<v-row>
									<v-col v-if="TypeCultive == 1">
										<v-data-table
											item-key="LlpID"												
											dense 
											:headers="headersBc"
											:items="itemsBc"
											group-by="TypeCaliberName"
											>
											<template v-slot:item.BrdBrixValue="{ item }">
													<s-text 
													disabled
													v-model="item.BrdBrixValue"/>
											</template>
											<template v-slot:item.Actions="{ item }">													
												<v-btn																											
													color="error"
													@click="deleteItemBc(item)"
													x-small>
													<v-icon>mdi-close</v-icon></v-btn
												>												
											</template>
											<template v-slot:item.BxcId="{ item }">
												<s-select														
													disabled
													clearable
													:items="itemColorVariety"
													item-text="BxcColorDescription"
													item-value="BxcID"
													v-model="item.BxcID"
													ref="colorSelect"/>
											</template>										
										</v-data-table>
									</v-col>
									<v-col v-else>
										<v-data-table
											item-key="LlpID"												
											dense 
											:headers="headersBco"
											:items="itemsBc"
											group-by="TypeCaliberName">		 
											<template v-slot:item.BrxWeightBegin="{ item }">
													<s-text 
														disabled
														v-model="item.BrxWeightBegin"/>
											</template>
											<template v-slot:item.BrxWeightEnd="{ item }">
													<s-text 
														disabled
														v-model="item.BrxWeightEnd"/>
											</template>
											<template v-slot:item.BrxPercentage="{ item }">
													<s-text 
														disabled
														v-model="item.BrxPercentage"/>
											</template>
											<template v-slot:item.Actions="{ item }">													
													<v-btn																										
														color="error"
														@click="deleteItemBc(item)"
														x-small>
														<v-icon>mdi-close</v-icon>
													</v-btn>												
											</template>										
										</v-data-table>
									</v-col>
								</v-row>									
							</div>
						</v-col>
					</v-col>

					<!--no olvidar de ponerle si tiene permisos de ver daños
					v-if="this.$fun.hasSpecialPermission('samplingdamage')"
					-->
					<v-col
						v-else
						cols="12"
						md="12"
						class="pt-0"
					>
						<v-container
							 	>

							<h3>Muestreo de Calidad (Daños)</h3>
							<sample-damage		
								v-if="objForeman" 					 					 
								:dataSampleDamage="objForeman"
								:sampleRcfID="sampleRcfID"			
								:TypeCultive="TypeCultive"				 								 
							></sample-damage>
							 
						</v-container>
					</v-col>


				</v-row>

				<v-container>
					
				<v-row >
					<v-col cols="12"> 
							<v-btn
							color="secondary"
							fab
							x-small
							dark
							@click="openCloseAcp"
							>
							<i class="fas fa-arrow-down"></i>
							</v-btn>
						
						Observaciones - Acciones correctivas y preventivas
					</v-col>
				</v-row>
				<v-card v-if="acp">
				<v-container  v-if="this.$fun.hasSpecialPermission('samplingdamage')">
					<v-row >
				<!-- <v-col class="pb-0">
					<s-select-definition
					:def="1312"
					label="Alerta"
					v-model="item.TypeAlert"
					></s-select-definition>
				</v-col>
			--> 
					<v-col cols="12">
					<s-textarea label="Observaciónes de Daños" v-model="SquObservation">
					</s-textarea>
					</v-col>
					<v-col cols="12">
					<s-textarea label="Acciones correctivas y preventivas de Daños" v-model="SquCorrectiveActions">
					</s-textarea>
					</v-col>
					<v-col cols="12">
						<v-btn 
						small
						@click="savesampledamagesc"
						>Guardar</v-btn>
					</v-col> 
					</v-row>
				</v-container>
				<v-container  v-if="this.$fun.hasSpecialPermission('samplingbrix')">
					<v-row >
				<!-- <v-col class="pb-0">
					<s-select-definition
					:def="1312"
					label="Alerta"
					v-model="item.TypeAlert"
					></s-select-definition>
				</v-col>
			--> 
					<v-col cols="12">
					<s-textarea label="Observación de Brix" v-model="SquObservationBrix">
					</s-textarea>
					</v-col>
					<!--<v-col cols="12">
					<s-textarea label="Acciones correctivas y preventivas" v-model="SquCorrectiveActions">
					</s-textarea>
					</v-col>-->
					<v-col cols="12">
						<v-btn 
						small
						@click="savesamplebrix"
						>Guardar</v-btn>
					</v-col> 
					</v-row>
				</v-container>
				</v-card>
				</v-container>


				<!--<v-row>
					<v-col>
						<v-btn block color="warning" @click="exit()"> Cancelar </v-btn>
					</v-col>
					<v-col>
						<v-btn block color="primary" @click="saveHead">
							Guardar
						</v-btn>
					</v-col>
				</v-row>	-->	
			</v-card>
		</v-col>	
		<v-dialog
			v-model="processingSAP"
			:persistent="messageProcessingSAP.length == 0"
			width="400"
		>
			<v-row style="margin: auto">
				<v-col cols="12">
					<v-card color="primary" dark>
						<!-- <v-container> -->
						<v-card-text v-if="messageProcessingSAP.length == 0">
							Por favor espere
							<v-progress-linear
								indeterminate
								color="white"
								class="mb-0"
							></v-progress-linear>
						</v-card-text>

						<v-card-text v-else>
							<div class="text--white pt-4">
								Error al realizar operación.<br />
								{{ messageProcessingSAP }}
							</div>
						</v-card-text>
						<!-- </v-container> -->
					</v-card>
				</v-col>
			</v-row>
		</v-dialog>
	</div>
</template>
<script>
	import _sBrixFreshService from "@/services/FreshProduction/BrixFreshService";
	import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService";
	import _sDamage from "@/services/FreshProduction/SampleDamage/SampleQuality";
	import SampleDamage from "@/views/FreshProduction/RawMaterialReception/SampleDamage/SampleDamage";
	import _sParameter from "@/services/General/ParameterService.js";
	import _sWeighedByBatch from "../../../services/FreshProduction/WeighedByBatchService";
	import _sDefinition from "../../../services/General/DefinitionService.js";
	import _sGenParamService from "@/services/General/ParameterService";
	import _sBrixDetail from "@/services/FreshProduction/PrfBrixDetailService";
 
	export default {
		components: { SampleDamage },
		props: {
			items: null,
		},

		data: () => ({
			SquCorrectiveActions: "",
			 SquObservation: "",
			 SquObservationBrix: "",
			 acp:false,
			sampleRcfID: {},
			SquAwning: false,
			SquCleaning: false,
			/*switchv: {},*/
			itemsCaliber: [],
			alertInfoSampling: false,
			guideSelected: {},
			sampleTotal: false,
			responseDamage: false,
			clickSendDamage: false,
			isBrix: false,
			isDamage: false,
			openDerive: false,
			numberGuideInternal: null,
			GrossWeight: 0,
			dialogCapataz: false,
			objForeman: null,
			objForemanSend: null,
			maxBRIX: 0,
			minBRIX: 0,
			quantityBRIX: 0,
			percentageSample: 0,
			loadDamage: false,
			itemsGang: [],
			itemSampleDamage: [],
			isEdit: false,
			itemsDataColor: [],
			itemsDataBrix: [],
			itemSample: {},
			itemsDeleteBrix: [],
			itemsDeleteColor: [],
			TypeCaliber1: 0,
			DedDescription1: "",
			TypeCaliber2: 0,
			DedDescription2: "",
			BrdBrixValue: "",
			Actions: "",
			itemColorVariety: [],
			HexadecimalColor: "",
			ColorDescription: "",
			ColorID: 0,
			ColorTextDescription: null,
			itemsData: {},
			BrxID: 0,
			objDeriveDestiny: null,
			headersBrix: [
				{ text: "Calibre", value: "TypeCaliberName" },
				{ text: "Brix", value: "BrdBrixValue", width: 70 },
				{ text: "", value: "Actions", width: 80 },
			],
			headersColor: [
				{ text: "Calibre", value: "TypeCaliberName" },
				{ text: "Color", value: "BxcColorDescription", width: 150 },
				{ text: "", value: "Actions", width: 80 },
			],
			itemsNumberGuide: [],
			RcfID: null,
			active: false,
			allCalibrated: [],
			processingSAP: false,
			errorSap: false,
			messageProcessingSAP: "",
			NumGroupDef: 0,
			TypeProcessingPlant: 0,
			errorsBegin: 0,
			errorsEnd: 0,
			line: "",
			cantFilasMuestreoBrix: 1,
			typeCultiveName: "",
			TypeCultive:0,
			itemBc: {},
			headersBc: [				
				/*{ text: "Item", value: "correlative", width: 80 },*/
				{ text: "Calibre", value: "TypeCaliberName", width: 80 },
				{ text: "Valor Brix", value: "BrdBrixValue", width: 80 },
				{ text: "Color", value: "BxcId", width: 80 },
				{ text: "Acciones", value: "Actions", width: 40 },
			],
			itemsBc: [],
			headersBco: [				
				/*BrxWeightBegin	BrxWeightEnd	BrxPercentage */
				{ text: "Calibre", value: "TypeCaliberName", width: 80 },
				{ text: "P. Inicio", value: "BrxWeightBegin", width: 80 },
				{ text: "P. Fin", value: "BrxWeightEnd", width: 80 },
				{ text: "Porcentaje", value: "BrxPercentage", width: 80 },
				{ text: "Acciones", value: "Actions", width: 30 },
			],
			BcHead: {},
			isSample: 0,
			showDamageIf: false,
		}),

		watch: {
			objForeman(){
				console.log("change objForeman");
				
			
				console.log("this objForeman", this.objForeman.RcfID);
				if(this.objForeman.RcfID != undefined){
					this.showDamageIf = true;	
				}	
			},
			itemsBc(){
				this.BcHead.BrxNumberJabas = this.itemsBc.length;
			},
			SquObservationBrix(){
				
			}
		},

		methods: { 
			calcPercent(){
				 
				this.itemBc.BrxPercentage = ((this.itemBc.BrxWeightEnd / this.itemBc.BrxWeightBegin) * 100).toFixed(4)
				console.log("this.itemBc.BrxPercentage", this.itemBc.BrxPercentage)
			},
			savesamplebrix(){
				_sDamage.saveObsBrix(
					{
						RcfID: this.objForeman.RcfID,
						NtpIDForeman: this.objForeman.RcfGngID,
						SquObservationBrix: this.SquObservationBrix
					}, this.$fun.getUserID())
				.then((r) => {
					if (r.status == 200) {
					this.$fun.alert("Guardado Correctamente", "success");
					}
				})
			},
			savesampledamagesc(){
				//SquCorrectiveActions SquObservation
				//sampleRcfID.SquObservation sampledamagesc
				//sampleRcfID.SquCorrectiveActions
				let sampledamagesc = {};
				/*if (this.SquObservation == "") {
					this.$fun.alert("", "warning");
					return;
				}

				if (this.SquCorrectiveActions == "") {
					this.$fun.alert("", "warning");
					return;
				}*/

			 	sampledamagesc.SquObservation = this.SquObservation;
			 	sampledamagesc.SquCorrectiveActions = this.SquCorrectiveActions;

				sampledamagesc.RcfID = this.RcfID.RcfID;
				sampledamagesc.RcfGngID = this.objForeman.RcfGngID;
				console.log("this.sampledamagesc", sampledamagesc);

				_sDamage.saveFooter(sampledamagesc, this.$fun.getUserID())
				.then((r) => {
					if (r.status == 200) {
					this.$fun.alert("Guardado Correctamente", "success");
					}
				});

			},
		
 
			openCloseAcp(){
				this.acp = !this.acp;
			},
			getsampledamagesc(){

				_sDamage.findSample({
					SquID: 0,
					RcfID: this.RcfID.RcfID,
					NtpIDForeman: this.objForeman.RcfGngID
				}, this.$fun.getUserID())
				.then((r) => {

					 console.log("data", r.data);	
					 this.sampleRcfID = r.data; 
					let awnin = r.data.SquAwning;
					let cleani = r.data.SquCleaning;
 
					this.SquAwning = false;	
					this.SquCleaning = false;

					if (awnin == 1) {
						this.SquAwning = true;											
					}
					if (cleani == 1) {
						this.SquCleaning = true;
						console.log("soy 1");
					}
					this.SquCorrectiveActions = r.data.SquCorrectiveActions;
					this.SquObservation = r.data.SquObservation;

				});
			},
			 
			saveswitchv(){

				let switchv = {};

				switchv.SquID = 0;
				switchv.RcfID = this.RcfID.RcfID;
				switchv.NtpIDForeman = this.objForeman.RcfGngID;

				if(this.SquAwning){
					switchv.SquAwning = 1;
				} else{
					switchv.SquAwning = 0;	
				}
				if (this.SquCleaning) {
					switchv.SquCleaning = 1;
				}else{
					switchv.SquCleaning = 0;
				}
				  
				console.log("this.SquAwning", this.SquAwning);
				 
				_sDamage.saveswitchv(switchv, this.$fun.getUserID())
				.then((r) => {
					this.$fun.alert("Datos guardados correctamente", "success");
				});
			},
			saveItemBc(type){
				if (this.itemBc.TypeCaliber == undefined) {
					this.$fun.alert("No ha seleccionado un calibre","warning");
					return;
				}
				if(type == 1){
					if (this.itemBc.BrdBrixValue == undefined || this.itemBc.BrdBrixValue <= 0) {
						this.$fun.alert("El valor del brix debe ser mayor a 0","warning");
						return;
					}
					if (this.itemBc.BxcID == undefined || this.itemBc.BxcID <= 0) {
						this.$fun.alert("No ha selecionado un color","warning");
						return;
					}
					this.itemBc.BrdBrixValue = parseFloat(this.itemBc.BrdBrixValue);
				}
				/*BrxWeightBegin	BrxWeightEnd	BrxPercentage  */
				if(type == 2){
					
					if (this.itemBc.BrxWeightBegin == undefined || this.itemBc.BrxWeightBegin <= 0) {
						this.$fun.alert("El valor del P. Inicial debe ser mayor a 0","warning");
						return;
					}
					if (this.itemBc.BrxWeightEnd == undefined || this.itemBc.BrxWeightEnd <= 0) {
						this.$fun.alert("El valor del P. Fin debe ser mayor a 0","warning");
						return;
					}
					if (this.itemBc.BrxPercentage == undefined || this.itemBc.BrxPercentage <= 0) {
						this.$fun.alert("El valor del Porcentaje debe ser mayor a 0","warning");
						return;
					}
				}
				/*this.itemBc.TypeCaliber = this.itemBc.TypeCaliberObj.TypeCaliber;
				this.itemBc.TypeCaliberName = this.itemBc.TypeCaliberObj.TypeCaliberName;
				//this.itemBc.TypeCaliberObj = "";*/
				let tcaliber = this.itemBc.TypeCaliber;

				let correlative = this.itemsBc.length + 1;
				this.itemBc.correlative = correlative;
				this.itemBc.TypeCultive = this.TypeCultive;
				this.itemBc.UsrCreateID = this.$fun.getUserID();
				this.itemBc.BrxID = this.BcHead.BrxID;
				this.itemBc.SecStatus = 1;

				this.itemBc.RcfID = this.RcfID.RcfID;
				this.itemBc.NtpIDForeman = this.objForeman.RcfGngID;

				
				console.log("this.itemBc", this.itemBc)
				 
				_sBrixDetail.save(this.itemBc, this.$fun.getUserID())
					.then((r) => {
						console.log("se agrego correctamente", r);
						if(r.status == 200){
							 
							this.itemBc = {};
							this.BcHead.BrxNumberJabas = this.itemsBc.length;

							 this.itemBc.TypeCaliber = tcaliber ;

							this.$fun.alert("Se Agrego correctamente.", "success");

							//loading list
							this.listBrixDetail();
						}
					});

				
			},
			listBrixDetail(){
				_sBrixDetail.list( { RcfID: this.items.RcfID, NtpIDForeman: this.objForeman.RcfGngID}, this.$fun.getUserID())
				.then((r) => {
					this.itemsBc = r.data;
					this.BcHead.BrxNumberJabas = this.itemBc.length;
					console.log("listando despues de agregar", this.itemsBc);
					//this.loadDamage = true;
				});
			},
			deleteItemBc(item){
							
				this.$fun.alert("¿Desea eliminar el registro?", "question")
				.then((r) => {

					if (r.value) {
						
						item.SecStatus = 0;
						_sBrixDetail.save(item, this.$fun.getUserID())
							.then((r) => {
								if(r.status == 200){
									this.itemsBc = this.itemsBc.filter(function(ele){ 
										return ele != item; 
									});
									this.BcHead.BrxNumberJabas = this.itemsBc.length;

									this.$fun.alert("Se elimino el registro", "success");
								}
							});
						console.log("item delte",item);
						console.log("this.itemsBc", this.itemsBc);
					}
				});
				

			},
			getColorByVariety() {

				console.log("this.RcfID.VrtID", this.RcfID.VrtID);

				_sBrixFreshService
					.getColorForVariety(this.RcfID.VrtID, this.$fun.getUserID())
					.then((r) => {
						this.itemColorVariety = r.data;
						if (this.itemColorVariety.length > 0)
							this.ColorTextDescription = this.itemColorVariety[0];

					});
			},		 
			inputNumberGuide(itemGuide) {

				if(itemGuide == undefined){
					return;
				}
 

				//que oculte las ventanas hasta seleccionar el
				this.RcfID = itemGuide;
				this.showDamageIf = false;
				console.log("elegi una guia", itemGuide);

				this.TypeCultive = itemGuide.TypeCultive;

				this.guideSelected = itemGuide;
				this.NumGroupDef = itemGuide.NumGroupDef

				//loading caliber
				this.getItemsCaliberByCultive(itemGuide.NumGroupDef);

				this.alertInfoSampling = true;
				if (itemGuide.RcfID > 0) {

					this.itemsBc = [];

					_sReceptionFresh
						.GetGang({ RcfID: itemGuide.RcfID }, this.$fun.getUserID())
						.then((r) => {
							this.itemsGang = r.data;
							console.log("capatz samir", r.data);
							if(this.itemsGang.length > 0){
								this.TypeProcessingPlant = this.itemsGang[0].TypeProcessingPlant;
								console.log("🚀 ~ file: BrixFresh.vue ~ line 552 ~ .then ~ this.itemsGang", this.TypeProcessingPlant)
							}
							this.items.RcfID = itemGuide.RcfID;
							this.validatePermission();
						});
				}
			},
			loadNumberGuia() {
				_sBrixFreshService
					.getBrixGuide({}, this.$fun.getUserID())
					.then((r) => {
						this.itemsNumberGuide = r.data;
						console.log("this.itemsNumberGuide", this.itemsNumberGuide);
					});
			},			 
			exit() {
				this.$emit("close");
			},
			close() {
				this.$emit("close");
				this.isDamage = false;
				this.isBrix = false;
			},
			inputForeman(item) {
				this.SquObservationBrix = "";
				console.log("entro en capataz", item);
				 this.showDamageIf = false;

				console.log("false destruir el objeto", this.showDamageIf);
				if (item == null) {
					return;
				}			
				if (item.RcfGngID <= 0) {
					return;
				}
				this.objForeman = {};
					this.SquObservationBrix = item.SquObservationBrix;
			 
				console.log("selecione capataz samir", item);	
				//this.active = true;
				this.objForeman = item;

				console.log("captaz sel", this.objForeman );
				
				this.getsampledamagesc();
				console.log("coloque en true a showDamageIf", this.showDamageIf);

				this.getColorByVariety();
				 //loading list
				this.listBrixDetail();

				
				console.log("true");			 
				

				console.log("levantar el objeto", this.showDamageIf);
			},
			 
			save(itemDamage) {
				
				this.validatePermission();

				if (this.objForeman.RcfGngID == undefined) {
					this.$fun.alert("Seleccione Capataz", "warning");
					return;
				}

				if (this.isValidated()) {
					this.itemsData.RcfID = this.items.RcfID;
					this.itemsData.BrxVb = this.items.BrxVb;
					this.itemsData.BrxStatus = 1;
					this.itemsData.SecStatus = 1;
					this.itemsData.UsrUpdateID = this.$fun.getUserID();
					this.itemsData.UsrCreateID = this.$fun.getUserID();

					itemDamage.NtpIDForeman = this.objForeman.RcfGngID;
					itemDamage.RcfID = this.RcfID;
					itemDamage.SecStatus = 1;
					itemDamage.SquStatus = 1;
					itemDamage.UsrUpdateID = this.$fun.getUserID();
					itemDamage.UsrCreateID = this.$fun.getUserID();

					this.itemsData.NtpIDForeman = this.objForeman.RcfGngID;
					this.itemsData.BrxNumberJabas = this.itemSample.BrxNumberJabas;
					this.itemsData.UnitMeasurement =
						this.itemSample.UnitMeasurement;

					this.itemsData.BrxID = this.itemSample.BrxID;
					this.itemsDataBrix.map((x) => {
						x.UsrCreateID = this.$fun.getUserID();
					});
					this.itemsData.DataBrixDetail = [];							
					if (this.isBrix) {
						console.log("Guardar brix",this.itemsDataBrix);

						let err = 0;
						this.itemsDataBrix.forEach(element => {
							
							if(element.BrxWeightBegin == "" && element.BrxWeightEnd != 0){
								err = err + 1;
							}
							if(element.BrxWeightBegin != 0 && element.BrxWeightEnd == ""){
								err = err + 1;
							}
						})

						if(err > 0){
							this.$fun.alert("Falta ingresar un peso", "warning");
							return;
						}

						 
							//this.itemsDataBrix = [...itemsDetails];
							this.itemsData.DataBrixDetail = this.itemsBc;

							if (this.itemsBc.length == 0) {
								return;
							}

							if (this.itemSample.BrxNumberJabas <= 0) {
								this.$fun.alert("Numero de jabas debe ser mayor a 0", "warning");
								return;
							}

							this.$fun
								.alert("Seguro de Guardar Brix?", "question")
								.then((v) => {
									if (v.value) {
										this.processingSAP = true;
										this.itemsData.TypeCultive =
											this.items.TypeCultive;
										_sBrixFreshService
											.save(this.itemsData, this.$fun.getUserID())
											.then(
												(r) => {
													if (r.status == 200) {
														this.processingSAP = false;
														this.$fun.alert(
															"Registrado correctamente",
															"success"
														);
														this.loadNumberGuia();
 
													}
												},
												(e) => {
													this.errorSap = true;
													this.messageProcessingSAP =
														e.response.data.Message;
													this.processingSAP = true;
												}
											);
									}
								});
						 
					}

					if (this.isDamage) {
						this.clickSendDamage = true;
					}

					//PARA DAMAGE
					if (this.isDamage) {

						console.log("Guardar damage", itemDamage );

						itemDamage.RcfID = null;
						itemDamage.RcfID = this.items.RcfID;

						this.$fun
							.alert("Seguro de Guardar Daños?", "question")
							.then((v) => {
								if (v.value) {
									_sDamage
										.save(itemDamage, this.$fun.getUserID())
										.then((re) => {
											if (re.status == 200) {
												this.$fun.alert(
													"Registrado correctamente",
													"success"
												);
												// this.close()
												// this.exit()

												// volvemos a cargar los daños
												this.loadNumberGuia();

												_sReceptionFresh
													.GetGang(
														{ RcfID: this.items.RcfID },
														this.$fun.getUserID()
													)
													.then((r) => {
														this.itemsGang = r.data;
														this.validatePermission();
														//console.log('lista de capataz ', this.itemsGang);
													});
												this.getSample();
												this.clickSendDamage = false;
											}
										});
								}
							});
					}
				}
			},
			saveHead(){
				console.log("this.BcHead", this.BcHead);

				if(this.isSample == 1){
					if(this.itemsBc.length <= 0){
						this.$fun.alert("No hay registros de muestreo", "success");
						return;
					}
					if(this.BcHead.UnitMeasurement <= 0){
						this.$fun.alert("No se ha seleccionado Unidad de Muestreo", "success");
						return;
					}
				}

				_sBrixDetail.saveHead(this.BcHead, this.$fun.getUserID())
				.then((r) => {
					if(r.status == 200){
						this.$fun.alert("Los datos han sido guardados correctamente", "success");
					}
				});
			},
			 
			//verificar sus persmisos
			validatePermission() {
				if (this.$fun.hasSpecialPermission("samplingbrix")) {
					this.isBrix = true;
					this.isDamage = false;
				}
				if (this.$fun.hasSpecialPermission("samplingdamage")) {
					this.isDamage = true;
					this.isBrix = false;
				}

				if (
					this.$fun.hasSpecialPermission("samplingbrix") &&
					this.$fun.hasSpecialPermission("samplingdamage")
				) {
					this.isBrix = true;
					this.isDamage = true;
				}

				console.log("this.isBrix",this.isBrix);
				console.log("this.isDamage",this.isDamage);
			},

			getItemsCaliberByCultive(groupDef) {
 
					_sDefinition
						.definitiongroup(1266, groupDef, this.$fun.getUserID())
						.then((r) => {
							 this.itemsCaliber = r.data;
							 console.log("caliber", this.itemsCaliber);
						/*	r.data.forEach((element) => {
								element.TypeCaliberName = element.DedDescription;
								element.TypeCaliber = element.DedValue;
							});

							let itemsCalibre = r.data;
							this.allCalibrated = _.groupBy(
								itemsCalibre,
								"DedDescription"
							);	*/					
						});				 
			},
			//fin de verificar persmisos
		},

		created() {
			
			this.itemBc.TypeCaliberMA = ["CAL 6", "CAL 7", "CAL 8", "CAL 9", "CAL 10"]

			this.loadNumberGuia();
			this.validatePermission();

			let a = [];
			a.push(
				"MinimoBRIX",
				"MaximoBRIX",
				"CantidadMuestraBRIX",
				"PorcentajeMuestraLote"
			);
			_sParameter.list(a, this.$fun.getUserID()).then((r) => {
				this.maxBRIX = r.data[0];
				this.minBRIX = r.data[1];
				this.quantityBRIX = r.data[2];
				this.percentageSample = r.data[3];
			});
		},

		mounted() {
			//this.switchv.SquAwning = true;
			//this.switchv.SquCleaning = true;
		},
	};
</script>