<template>
	<div>
		<v-row>
			<v-col cols="6" class="s-col-form">
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar 
						label="1.- Información Recepción"
						color="#E0F7F2"
					></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Peso Guía"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.RcfWeightGuide | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Peso Neto"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.WbbNetWeight | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Peso Actualizado"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.WbbNetWeight | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="6" class="s-col-form">
				<!--  -->
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar 
						label="2.- Tolerancia Descarte Seleccion"
						color="#E0F7F2"
					></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Tolerancia aceptada"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<s-text
									v-model="AcceptedToleranceInputDiscart"
									decimal
								
								/> 
<!-- :readonly="lotReception.RcfStatusSap == 2" -->
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.tolWeiDisSelToleranceAcceptedCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Descarte no aceptada"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 style="color : red">
									{{ lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage | numberDefault }}%
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6" class="s-col-form">
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar 
						label="3.- Descuentos totales"
						color="#E0F7F2"
					></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Descarte no aceptada"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 style="color : red">
									{{ lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAcceptedPercentage | numberDefault }}%
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Peso a pagar"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisTotWeightPay | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 style="color : red">
									{{ lotReception.DisTotWeightPayPercentage | numberDefault }}%
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="6" class="s-col-form">
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar 
						label="4.- Distribución Almacenes"
						color="#E0F7F2"
					></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Peso Final aceptable"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.RfsWeigth | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="success--text font-weight-black">
									{{"Alm. 02"}}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{"Merma aceptable"}}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.RfsWeightDiscart | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="red--text font-weight-black">
									{{"Alm. 20"}}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso de bolillo" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.RfsWeightBolillo | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="info--text font-weight-black">
									{{ "Bolillo" }} 
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso de Devolucion" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.RfsWeightDevolution | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="info--text font-weight-black">
									{{ "Devolucion" }} 
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	export default {
		props: {
			lotReception: { }
		},

		data() {
			return {
				// lotReception: {},
				AcceptedToleranceInput : 0,
				AcceptedToleranceInputDiscart : 0,
			}
		},

		watch: {
			AcceptedToleranceInput()
			{
				this.getCalcule();
			},

			AcceptedToleranceInputDiscart()
			{
				this.getCalcule();
			},
		},

		methods: {
			getCalcule() {

				 
				this.lotReception.infRefDifference = (this.lotReception.RcfWeightGuide - this.lotReception.WbbNetWeight).toFixed(2);
				this.lotReception.infRefDifferencePercentage = ((this.lotReception.infRefDifference/this.lotReception.RcfWeightGuide)*100).toFixed(2);

				//samir
				// this.lotReception.discartPercentage = 
				console.log({ 
					AcceptedToleranceInputDiscart: this.AcceptedToleranceInputDiscart,
					discartPercentage: this.lotReception.discartPercentage
				 })

				//  if( !this.AcceptedToleranceInputDiscart ) return; 

				if ( parseFloat(this.AcceptedToleranceInputDiscart) >=  parseFloat(this.lotReception.discartPercentage) ) {
					this.lotReception.tolWeiDisSelToleranceAcceptedPercentageInput = parseFloat(this.lotReception.discartPercentage);
					// this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = 0;
				//this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = (this.lotReception.RcfWeightGuide*(this.lotReception.tolWeiDisSelToleranceAcceptedPercentageInput/100)).toFixed(2);
				this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = this.lotReception.discart;
					this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = 0;
					this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate =0;

					console.log({ if : 1 })

					this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.lotReception.discartPercentage;//SAMIR
					//this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.AcceptedToleranceInputDiscart;//SAMIR
				} 
				
				if ( parseFloat(this.AcceptedToleranceInputDiscart) < parseFloat(this.lotReception.discartPercentage) ){
					this.lotReception.tolWeiDisSelToleranceAcceptedPercentageInput = parseFloat(this.AcceptedToleranceInputDiscart);
					// this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = (this.lotReception.RcfWeightGuide*(this.AcceptedToleranceInputDiscart/100)).toFixed(2);
					this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = (this.lotReception.WbbNetWeight*(this.AcceptedToleranceInputDiscart/100)).toFixed(2);
					// this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = parseFloat(this.AcceptedToleranceInputDiscart);
					this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = (this.lotReception.discartPercentage -this.AcceptedToleranceInputDiscart).toFixed(2);
					this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = (this.lotReception.discart - this.lotReception.tolWeiDisSelToleranceAcceptedCalculate).toFixed(2);

					console.log({ if : 2 })
				
					this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.AcceptedToleranceInputDiscart;//SAMIR
					//this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.AcceptedToleranceInputDiscart;//SAMIR
				}
				// this.lotReception.tolWeiDisSelToleranceAcceptedPercentageInput = this.AcceptedToleranceInputDiscart;
				
				// this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = (this.lotReception.WbbNetWeight*(this.lotReception.tolWeiDisSelToleranceAcceptedPercentageInput/100)).toFixed(2);
				// this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = (this.lotReception.discartPercentage -this.AcceptedToleranceInputDiscart).toFixed(2);


				// this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = (this.lotReception.discart - this.lotReception.tolWeiDisSelToleranceAcceptedCalculate).toFixed(2);

				this.lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted = (parseFloat(this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate));
				
				this.lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAcceptedPercentage = 
				this.lotReception.RcfWeightGuide > 0
				? (parseFloat(this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate/this.lotReception.RcfWeightGuide)*100).toFixed(2)
				: 0
				this.lotReception.DisTotWeightPay = (this.lotReception.WbbNetWeight - this.lotReception.RfsWeightDevolution - this.lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted);
				this.lotReception.DisTotWeightPayPercentage = this.lotReception.RcfWeightGuide > 0
				? ((this.lotReception.DisTotWeightPay/this.lotReception.RcfWeightGuide)*100).toFixed(2)
				: 0

				this.lotReception.RfsWeightDiscart = (parseFloat(this.lotReception.tolWeiDisSelToleranceAcceptedCalculate)).toFixed(2);
				this.lotReception.RfsWeigth = (parseFloat(this.lotReception.DisTotWeightPay) - parseFloat(this.lotReception.RfsWeightDiscart) - parseFloat(this.lotReception.RfsWeightBolillo)).toFixed(2);


				//				
				// this.lotReception.AcceptedToleranceInput = this.AcceptedToleranceInput; 
				this.lotReception.AcceptedToleranceInputDiscart = this.AcceptedToleranceInputDiscart;

				this.lotReception.RfsWeightBolilloc = this.lotReception.RfsWeightBolillo;
			}
		},

		created () {
			 
		},
		mounted(){
			this.AcceptedToleranceInput = 0;
			this.AcceptedToleranceInputDiscart = this.lotReception.AcceptedToleranceInputDiscart ?? 0;
			
			this.getCalcule();
		}
	}
</script>
