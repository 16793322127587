import Service from "../Service";

const resource = "PrfBrixDetail/"

export default {
     
    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID }
        });
    },
    saveHead(obj, requestID) {
        return Service.post(resource + "saveHead", obj, {
            params: { requestID }
        });
    },
    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID }
        });
    },
}