<template>
    <v-card>
        <div class="modal">
            <div class="modal-content">
                <s-toolbar
                    label="Impresion de Recepcion de Fruta"
                    dark
                    :color="'#BAB6B5'"
                    close
                    @close="$emit('close')"
                    class="mb-6"
                    print @print="downloadPdf()"
                ></s-toolbar>
                
                <div id="testHtml" ref="testHtml" style="font-size: 10px;">
                    <div>
                         
                        <div style="align-items: center;border-bottom: 1.5px solid red;">
                              <table class="table-header" style="border: none;">
                                <tbody style="font-size: 10px;">
                                    <tr style="border: none;">
                                        <td rowspan="6" style="border: none;width: 30%;">
                                            <img  width="100px" 
                                                src="https://www.sunshineperu.com/assets/img/logo.svg"
                                                alt="User Image"
                                                ref="userImage"/> 
                                        </td>
                                        <td rowspan="6" style="border: none;text-align: center;width: 40%;">
                                            <p class="text-celd" style="margin-bottom: 0px;text-align: center;">GUIA INTERNA DE MATERIA PRIMA </p>
                                            <P class="text-celd" style="text-align: center;">RECEPCION  CONGELADOS</P>
                                            
                                        </td>
                                        
                                        <td style="border: none;text-align: end;"><span>Codigo :</span>
                                        </td>
                                        <td style="border: none;"><span>AP-FR-225</span>
                                        </td>
                                    </tr>
                                    <tr><td style="border: none;text-align: end;">Version :</td><td style="border: none;">04</td></tr>
                                    <tr><td style="border: none;text-align: end;">F. de Creaccion :</td><td style="border: none;">12/10/2013</td></tr>
                                    <tr><td style="border: none;text-align: end;">Ultima Revision :</td><td style="border: none;">05/01/2024</td></tr>
                                    <tr><td style="border: none;text-align: end;">Paginas :</td><td style="border: none;">1 de 1</td></tr>
                                    <tr><td style="border: none;text-align: end;">Anexos :</td><td style="border: none;">0</td></tr>
                                </tbody>
                            </table>                           
                        </div>
                       
                        <div style="align-items: center;">
                            <table class="" style="width: 100%;">
                                 <thead>
                                     
                                    <tr></tr>
                                    <tr></tr>
                                    <tr></tr>
                                 </thead>
                                <tbody class="table-body">
                                    <tr >                                    
                                        <td >Fecha Recepcion:  {{ $fun.formatDateView(referralGuideSelected.DateDocument) }} </td>
                                        <td > </td>
                                        <td style="border: 1px solid red;"> N° : {{ referralGuideSelected.RfsCode}} </td>                                      
                                    </tr> 
                                    <tr >                                    
                                        <td style="font-weight: bold;"><b>Proveedor : </b> {{ referralGuideSelected.PrdCardNameResponsable }} </td>
                                        <td > Lote de Materia Prima </td>
                                        <td > {{ referralGuideSelected.PrfYuliano }} </td>                                      
                                    </tr> 
                                    <tr >                                    
                                        <td style="font-weight: bold;"><b>Guia de Remision Remitente : </b> {{ referralGuideSelected.RcfNumberReferralGuide }} </td>
                                        <td > N° de Operación </td>
                                        <td style="border: 1px solid black;">  </td>                                      
                                   </tr>  
                                </tbody>                                    
                            </table>
                            <table class="table table-bordered" style="border-collapse: collapse; font-size: 10px; width: 100%;">
                                <tbody>
                                    <tr>
                                        <td style="padding-left: 20px;">Materia Prima : 
                                            
                                        </td>
                                        <td style="text-align: center;">
                                            {{ referralGuideSelected.TypeCultiveName }}</td>
                                        <td>Variedad :</td>
                                        <td colspan="2" style="text-align: center;">{{ referralGuideSelected.VrtName }}</td>
                                        <td colspan="2" style="text-align: center;">Materia Prima no Procesable</td>
                                        <td rowspan="2" style="text-align: center;">Motivo</td>
                                    </tr>
                                    <tr style="text-align: center;">
                                        <td style="">Datos de Recepcion</td>
                                        <td style="width: 60px;">Detalle</td>
                                        <!-- <td style="width: 60px;">Tipo de Envase</td> -->
                                        <td style="width: 60px;">Cantidad Calibrada</td>
                                        <td style="width: 60px;">Peso a Pagar(KG)</td>
                                        <td style="width: 60px;">Peso Calibrado (KG)</td>
                                        <td style="width: 60px;">Peso Descarte (KG)</td>
                                        <td style="width: 60px;">Descarte (%)</td>
                                    </tr>
                                    <tr>
                                        <td >Peso Guia (KG)</td>
                                        <td style="text-align: center;">{{ referralGuideSelected.RcfWeightGuide | numberDefault }}</td> 
                                        <!-- <td style="text-align: center;">{{ referralGuideSelected.TypeChargeName }}</td> -->
                                        <td style="text-align: center;">{{ referralGuideSelected.RcfCalibratedQuantity | numberDefault }}</td> 
                                        <td style="text-align: center;">{{ (parseFloat(referralGuideSelected.RfsWeigth) + parseFloat(referralGuideSelected.RfsWeightDiscart)) | numberDefault }}</td>
                                        <!-- <td style="text-align: center;">{{ referralGuideSelected.RcfWeightWash }}</td>  -->
                                        <td style="text-align: center;">{{ referralGuideSelected.RfsWeigth | numberDefault }}</td> 
                                        <td style="text-align: center;">{{ referralGuideSelected.tolWeiDisSelToleranceAcceptedCalculate | numberDefault }}</td>
                                        <!-- <td style="text-align: center;">{{ referralGuideSelected.RfsWeightDiscart }}</td> -->
                                        <td style="text-align: center;">{{ referralGuideSelected.tolWeiDisSelToleranceAcceptedCalculatePR | numberDefault }}</td> 
                                        <td ></td>
                                    </tr>
                                    <tr>
                                        <td >Peso Recepcion (KG)</td>
                                        <td style="text-align: center;">{{ referralGuideSelected.WbbNetWeight | numberDefault }}</td> 
                                        <!-- <td ></td> -->
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                    </tr>
                                    <tr>
                                        <td >Tipo de Envase </td>
                                        <td style="text-align: center;">{{ referralGuideSelected.TypeChargeName }}</td> 
                                        <!-- <td ></td> -->
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                        <td ></td>
                                    </tr>
                                    <tr >
                                        <td  style=" ">Cantidad Recepcionada </td>
                                        <td  style=" text-align: center;"> {{ referralGuideSelected.AmountJabasWeigh | numberDefault }}</td>
                                        <!-- <td  style="color: white;height: 17px;"> </td> -->
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                    </tr>
                                    <tr >
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                        <!-- <td  style="color: white;height: 17px;"> </td> -->
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                        <td  style="color: white;height: 17px;"> </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">Total (Kilos) a Pagar:</td>
                                        <td>{{ referralGuideSelected.RcfCalibratedQuantity | numberDefault }}</td>
                                        <!-- <td></td>  -->
                                        <td style="text-align: center;">{{ (parseFloat(referralGuideSelected.RfsWeigth) + parseFloat(referralGuideSelected.RfsWeightDiscart)) | numberDefault }}</td>
                                        <td>{{ referralGuideSelected.RfsWeigth | numberDefault }}</td>
                                        <td style="text-align: center;">{{ referralGuideSelected.tolWeiDisSelToleranceAcceptedCalculate | numberDefault }}</td>
                                        <!-- <td style="text-align: center;">{{ referralGuideSelected.RfsWeightDiscart }}</td> -->
                                        <td style="text-align: center;">{{ referralGuideSelected.tolWeiDisSelToleranceAcceptedCalculatePR | numberDefault }}</td> 
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br> 
                            <div style="border: 0.6px solid black;font-size: 10px;">
                                <p style="height: 25px;justify-content:start;padding: 2px;">Observaciones:</p>
                            </div>
                            <br>
                            <br>
                           
                        
                            <div  style="display: inline;font-size: 10px;"> 
                                <div style="margin-bottom: 0px;padding-bottom: 0px;width: 50%;text-align: center;display: inline-block;">______________________________</div>
                                <div style="margin-bottom: 0px;padding-bottom: 0px;width: 50%;text-align: center;display: inline-block;">______________________________</div>
                            </div>
                            <div  style="font-size: 10px;"> 
                                <div class="col-6 text-center" style="width: 50%;text-align: center;display: inline-block;">RECEPCION CONGELADO</div>
                                <div class="col-6 text-center" style="width: 50%;text-align: center;display: inline-block;">PROVEEDOR</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-card>
</template>
<script>
    import moment from 'moment';
    import VueHtml2pdf from 'vue-html2pdf'
    import jsPDF from 'jspdf';
    export default{ 
        props:{
            referralGuideSelected: {
				type: Object,
				default: null,
			},
        },
        components:{
            VueHtml2pdf
        },
        data(){
            return{
                
            }
        },
        methods:{
            downloadPdf(){
                // this.$refs.html2Pdf.generatePdf();
                const contentToExport = this.$refs.testHtml;
                const content = document.getElementById('testHtml').innerHTML;
     
                 // Crear una nueva ventana
                const printWindow = window.open('', '_blank');

                // Contenido que deseas imprimir
                printWindow.document.write(`
                <html>
                    <head>
                    <title>Imprimir</title>
                    <style>
                        /* Aquí puedes añadir estilos para la impresión */
                        body { font-family: Arial, sans-serif; }
                        h1 { color: blue; }
                        .table-container
{
    border: solid 1.5px black;
    /* border-radius: 10px; */
    width: 99% !important;
    /* border-collapse: collapse; */
}

.table-header th{
    width: 100px;
    border-bottom: 2px solid black;
    font-family: Arial Black, Helvetica, sans-serif;
    font-size: 9px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
}

.table-body td{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9px;
    line-height: 20px;
    padding: 5px;
    font-weight: bolder;
}

.table-body td:first-child{
    text-align: left;
    font-family: Arial Black, Helvetica, sans-serif;
    font-weight: 700;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


.modal-content {
  background-color: #fff;
  width: 210mm; /* Ancho de documento A4 */
  height: 297mm; /* Alto de documento A4 */
  padding: 20mm; /* Márgenes internos */
  border: 1px solid #000;
}

.table-header{
    border: solid 2px black;
    border-radius: 10px;
    width: 99% !important;
}
.table-header td + td {
    
    border-left: 2px solid black; /* Ajusta el color y el grosor de la línea según sea necesario */
    padding-left: 20px; /* Añade un espacio adicional a la izquierda para separar el contenido de la línea */
}

.text-celd{
    margin:0px;
    padding:2px;
    font-family:Arial Black, Helvetica, sans-serif;
    font-size: 10px;
    font-weight:bold;
    text-align:justify;
}

.table-bordered tr td{
    padding: 2px;
    border: 1px solid black;
}
                    </style>
                    </head>
                    <body>
                    ${content}
                    </body>
                </html>
                `);

                // Esperar a que se cargue el contenido
                printWindow.document.close();
                printWindow.focus();
                printWindow.print();
                printWindow.close();
                // RPT_General_Summary_Data_Maturation

                // const doc = new jsPDF({
                //     unit: 'mm',
                //     format: 'a4',
                //     orientation: 'portrait'
                // });

                // const fontSize = 8;
                // doc.setFontSize(fontSize);
                
                // const margins = { top: 10, bottom: 10, left: 10, right: 10 };
                
                // const columnWidth = [[100, 150,100,150],[70,70,70,70,70]];

                // const tables = contentToExport.querySelectorAll('table');

                // doc.html(contentToExport, {
                //     callback: () => {
                //         doc.save("impresion.pdf");
                //     },
                //     x: margins.left,
                //     y: margins.top,
                //     html2canvas: {
                //         scale: 0.3 // Escalar el contenido para reducir el tamaño
                //     }
                // });     
            },
           
            formatDate(date)
            {
                return moment(date).format('DD/MM/YYYY');
            }
        },
        mounted(){
            console.log("referralGuideSelected", this.referralGuideSelected);
        }
    }
</script>

<style>

.table-container
{
    border: solid 1.5px black;
    /* border-radius: 10px; */
    width: 99% !important;
    /* border-collapse: collapse; */
}

.table-header th{
    width: 100px;
    border-bottom: 2px solid black;
    font-family: Arial Black, Helvetica, sans-serif;
    font-size: 9px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
}

.table-body td{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9px;
    line-height: 20px;
    padding: 5px;
    font-weight: bolder;
}

.table-body td:first-child{
    text-align: left;
    font-family: Arial Black, Helvetica, sans-serif;
    font-weight: 700;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


.modal-content {
  background-color: #fff;
  width: 210mm; /* Ancho de documento A4 */
  height: 297mm; /* Alto de documento A4 */
  padding: 20mm; /* Márgenes internos */
  border: 1px solid #000;
}

.table-header{
    border: solid 2px black;
    border-radius: 10px;
    width: 99% !important;
}
.table-header td + td {
    
    border-left: 2px solid black; /* Ajusta el color y el grosor de la línea según sea necesario */
    padding-left: 20px; /* Añade un espacio adicional a la izquierda para separar el contenido de la línea */
}

.text-celd{
    margin:0px;
    padding:2px;
    font-family:Arial Black, Helvetica, sans-serif;
    font-size: 10px;
    font-weight:bold;
    text-align:justify;
}

.table-bordered tr td{
    padding: 2px;
    border: 1px solid black;
}
</style>