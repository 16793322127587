<template>
	<div>
		<!-- <v-toolbar class="elevation-0" dark color="primary" height="40">
			
			<h2>MUESTREO</h2>
			<v-spacer></v-spacer>

			<v-btn icon dark @click="$emit('close')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar> -->
		<s-toolbar
					label="Muestreo de Mango"
					dark
					:color="'#BAB6B5'"
					close
					@close="$emit('close')"
					class="mb-6"
				></s-toolbar>
				<v-alert
				v-model="alert"
				border="right"
				:color="'#9EB8F6'"
				dark
				dense
				shaped
				close-icon="fas fa-times-circle"
				style="border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 5%; margin-top: -22px;}"
			>
				<div>
					<v-row justify="center" style="margin-top: -10px;">
						<v-col lg="5">
							<b>Productor:</b> {{ items.PrdCardName }}
						</v-col>
						<v-col lg="4">
							<b>Lote: </b>{{ items.FltName }}
						</v-col>
					</v-row>

					<v-row
						justify="center"
						style="margin-top: -10px; font-size: smaller"
					>
						<v-col lg="3">
							<b>Tipo Cultivo:</b> {{ items.TypeCropName }}
						</v-col>
						<v-col lg="3">
							<b>Cultivo:</b> {{ items.TypeCultiveName }}
						</v-col>
						<v-col lg="3">
							<b>Variedad: </b>{{ items.VrtName }}
						</v-col>
					</v-row>
                    
				</div>
			</v-alert>

		<!-- <v-container> -->
			<v-col style="padding-top: 10px">
				<v-card class="pt-0 elevation-0 pb-0" elevation="0">
					<!-- datos de la cabecera -->
					<v-row>
						<v-col>					 
						<v-alert
							v-show="itemHead.FfsIsValid != 1"
							border="right"
							:color="'#f42658'"
							dark
							dense
							shaped
							close-icon="fas fa-times-circle"
							style="border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 5%; margin-top: -22px;}"
						>
							<h1>Resultado: LOTE NO APTO</h1>   
							<p>El Lote NO cumple con los parametros establecidos por calidad</p>        
						</v-alert>

						</v-col>
						</v-row>
					<v-row>
						<v-col cols="12" lg="2" md="2">
							<s-date
								 
								v-model="itemHead.FfsDateSampling"
								label="Fecha de muestreo"
							></s-date>
						</v-col>
						<v-col cols="12" lg="2" md="2">
							<s-load
								v-model="photoSampling"
								label="Imagen"
							></s-load>
						</v-col>
						<v-col  cols="12" lg="2" md="2"
							><s-select-definition
								disabled
								label="Unidad de Muestreo"
								v-model="itemHead.FsfUnitMeasurement"
								:def="1286"
							></s-select-definition
						></v-col>
						<v-col cols="6" lg="3" md="3">
							<s-text
								v-model="itemHead.FfsNumberJabas"
								ref="FocuesNumberJabas"
								decimal
								label="Unidades Muestreadas"
							></s-text>
						</v-col>

						<v-col cols="6" lg="3" md="3">
							<s-text
								v-model="itemHead.FfsSamplingWeight"
								ref="FocuesFfsSamplingWeight"
								decimal
								label="Peso muestra (KG)"
							></s-text>
						</v-col>

						<v-col cols="6" lg="3" md="3">
							<s-select
							:items="itemsDataVariety"
							item-text="VrtName"
							item-value="VrtID"
							return-object
							v-model="objVarietyBRIX"
							label="Variedad"
							ref="colorSelect"
							@input="inputCaliber($event)"
							></s-select>
						</v-col>

						
					</v-row>
					<v-row>
						<v-col cols="12" lg="12" md="12" sm="12" class="pt-0">
							<s-textarea
								v-model="itemHead.FfsObservations"
								label="Observaciones"
								outlined
								full-width
								rows="1 "
								row-height="15"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" lg="12" md="12" sm="12" class="pt-0">
							<s-select-definition 
								:def="1386" 
								label="Higiene del Camion" 
								v-model="itemHead.TypeCleaningTruck" />
						</v-col>
					</v-row>
					<!-- fin de datos de la cabecera -->
					<v-row>
						<v-col class="pt-0 pb-3"><v-divider></v-divider></v-col>
					</v-row>

					<v-row>
						
						<v-col cols="12" lg="6" class="pt-0">
							<v-row class="pt-3 pr-3 pl-3">
									<h3>Muestreo Brix | Color</h3>
							</v-row>

							<v-row>
								<v-col cols="6">
									<v-col>
										<v-row>	
											<h3 >Brix</h3>
											<v-spacer></v-spacer>
											<v-btn
												rounded
												color=""
												fab
												x-small
												@click="addBrix()"
												><i class="fas fa-plus"></i
											></v-btn>
										</v-row>
									</v-col>
									
									<v-divider></v-divider>

									<v-col>
										<v-row >
											<!-- <v-col
												cols="12"
												lg="6"
												md="6"
												sm="12"
												class="pl-0 pb-0"
											>
												<s-select
													:items="itemsDataVariety"
													item-text="VrtName"
													item-value="VrtID"
													return-object
													v-model="objVarietyBRIX"
													label="Variedad"
													ref="colorSelect"
													@input="inputCaliber($event)"
												></s-select>
											</v-col> -->
											<v-col
												cols="12"
												class="pl-0 pb-0"
											>
												<s-text
													v-model="FfsBrixValue"
													ref="focusBrixValue"
													label="Valor Brix"
													decimal
													@keyupEnter="addBrix()"
												></s-text>
											</v-col>
										</v-row>
										<v-row>
											<v-col class="pl-0 pt-0">
												<v-data-table
													dense
													:headers="headersBrix"
													:items="itemsDataBrix"
													hide-default-footer
													:items-per-page="-1"
													disable-sort
													item-key="Line"
												>
													<template
														v-slot:item.Actions="{ item }"
													>
														<v-btn
															@click="
																clickDeleteBrix(item)
															"
															x-small
															block
															rounded
															color="error"
														>
															<v-icon
																style="
																	font-size: 16px !important;
																"
																>mdi-close</v-icon
															>
														</v-btn>
													</template>
												</v-data-table>
											</v-col>
										</v-row>
									</v-col>
								</v-col>

								<v-col cols="6">
									
									<v-row class="pt-3 pr-3 pl-3">
										<h3>Color</h3> 
										<v-spacer></v-spacer>
										<v-btn rounded fab x-small @click="addColor()"
											><i class="fas fa-plus"></i
										></v-btn>
									</v-row> 

									<v-divider></v-divider>

									<v-container>
										<v-row >
											<v-col cols="16" class="pb-0 pl-0">
												<s-select
													:items="ColorCultive"
													item-text="CbcName"
													item-value="CbcID"
													v-model="ColorTextDescription"
													return-object
													label="Color"
													ref="colorSelect"
													@input="inputColor($event)"
												></s-select>
											</v-col>

											<!-- <v-col cols="6" class="pl-0 pb-0">
												<s-text 
													label="valor"
													decimal
													@keyupEnter="addColor()"
													v-model="FcfColorValueData">
													
												</s-text>
											</v-col> -->

										</v-row>
										<v-row>
											<v-col class="pt-0 pl-0">
												<v-data-table
													dense
													:headers="headersColor"
													hide-default-footer
													:items="itemsDataColor"
													:items-per-page="-1"
													disable-sort
												>
													<template
														v-slot:item.CbcName="{
															item,
														}"
													>
														{{ item.CbcName }}
														<v-chip
															x-small
															:color="
																 item.CbcColor + ''"
														></v-chip>
													</template>

													<template
														v-slot:item.Actions="{ item }">
														<v-btn
															@click="
																clickDeleteColor(item)
															"
															x-small
															color="error"
															dark
															block
															rounded
														>
															<v-icon
																style="
																	font-size: 16px !important;
																"
															>
																mdi-close</v-icon
															>
														</v-btn>
													</template>
												</v-data-table>
											</v-col>
										</v-row>
									</v-container>
								</v-col>
							</v-row>
						</v-col>

						<v-divider vertical></v-divider>
						<v-col cols="12" lg="6" class="pt-0">
							<h3>Defectos Externos</h3>
							<v-col style="padding-top: 30px">

								<v-row>
									<v-col cols="12" lg="6" md="6" class="pl-0 pb-0">
										<s-text
											v-model="itemHead.FfSsampledUnits"
											ref="FocuesFfSsampledUnits"
											label="Unidades de daños">

										</s-text>
									</v-col>
								</v-row>

								<v-row style="padding-bottom: 16px">
									<!-- <v-col
										cols="12"
										lg="4"
										md="4"
										sm="12"
										class="pl-0 pb-0"
									>
										<s-select
											:items="itemsDataVariety"
											item-text="VrtName"
											item-value="VrtID"
											return-object
											v-model="objVarietyDamage"
											label="Variedad"
											ref="colorSelect"
											@input="inputVariety3($event)"
										></s-select>
									</v-col> -->
								

									<v-col
										cols="12"
										lg="6"
										md="6"
										class="pl-0 pb-0"
									>
										<s-text
											v-model="itemDamage.FdfAmountFruit"
											type="decimal"
											label="Cantidad fruta"
											ref="quantityDamage"
										></s-text>
									</v-col>
									<v-col
										cols="12"
										lg="6"
										md="6"
										class="pl-0 pb-0"
									>
										<s-select
										 label="Tipo de defecto"
										 :items="DamageCultive"
										 v-model="itemDamage.ObjTypeDefects"
										 item-text="DfcName"
										 item-value="DfcID"
										 return-object
										 @input="inputDamage($event)">

										</s-select>
										<!-- <s-select-definition
											v-model="itemDamage.ObjTypeDefects"
											:def="1327"
											return-object
											label="Tipo de defecto"
											
										>
										</s-select-definition> -->
									</v-col>
								</v-row>
								<v-row>
									<h3>Muestras</h3>
									<v-spacer></v-spacer>
									<v-btn
										rounded
										fab
										x-small
										@click="addDamage()"
										><i class="fas fa-plus"></i
									></v-btn>
								</v-row>
								<v-divider></v-divider>
								<!-- <v-container style="padding-top: 20px"> -->
									<v-row class="mt-5">
										<v-col class="pl-0 pt-0">
											<v-data-table
												style="padding-bottom: 16px"
												dense
												:headers="headerDamage"
												:items="itemsDataDamage"
												hide-default-footer
												:items-per-page="-1"
												disable-sort
												item-key="Line"
											>
												<template
													v-slot:item.Actions="{
														item,
													}"
												>
													<v-btn
														@click="
															clickDeleteDamage(
																item
															)
														"
														x-small
														block
														rounded
														color="error"
													>
														<v-icon
															style="
																font-size: 16px !important;
															"
															>mdi-close</v-icon
														>
													</v-btn>
												</template>
											</v-data-table>
										</v-col>
									</v-row>
								<!-- </v-container> -->

								<!-- botones -->
								<v-row style="padding-top: 40px">
									<v-spacer></v-spacer>
									<v-col cols="12" lg="6" md="6">
										<v-btn block rounded color="warning"
											>Cancelar</v-btn
										>
									</v-col>
									<v-col cols="12" lg="6" md="6">
										<v-btn
											class="ml-3"
											block
											rounded
											color="primary"
											@click="save()"
											>Guardar</v-btn
										>
									</v-col>
								</v-row>
							</v-col>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		<!-- </v-container> -->
	</div>
</template>
<script>

	import _sDamageFlawCultiveService from "@/services/FrozenProduction/DamageFlawCultiveService";
	import _sCultiveByColorService from "@/services/FrozenProduction/CultiveByColorService";

	import _sBrixFreshService from "@/services/FreshProduction/BrixFreshService";
	import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService";
	import _sDamage from "@/services/FreshProduction/SampleDamage/SampleQuality";
	import SampleDamage from "@/views/FreshProduction/RawMaterialReception/SampleDamage/SampleDamage";
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	import _sParameter from "@/services/General/ParameterService.js";
	import _sHelper from "@/services/HelperService";

	import _sFrozenSampling from "@/services/FrozenProduction/FrzfrozenSampling.js";
	import _sReceptionStorageService from "../../../services/Technicalassistance/ReceptionStorageService";
	import _sTechnicalSpecificationService from "@/services/FrozenProduction/FrzTechnicalSpecificationService.js"

	// SERVICIO CREADO POR DAVID 
	import _sFrzTechnicalspecificationsByCultive from "@/services/FrozenProduction/FrzTechnicalSpecificationsByCultiveService.js";

	export default {
		components: { SampleDamage, SSelectVariety },
		props: {
			items: null,
			TypeShip: null,
		},

		data: () => ({
			FcfColorValueData : "",
			DamageCultive : null,
			ColorCultive: null,
			objVarietyBRIX: null,
			objVarietyColor: null,
			objVarietyDamage: null,
			itemHead: {},
			photoSampling: [],
			itemsDataVariety: [],
			itemDamage: {},
			itemDamageData: [],
			FfsID: 0,
			dialogCapataz: false,
			objForeman: null,
			objForemanSend: null,
			maxBRIX: 0,
			minBRIX: 0,
			quantityBRIX: 0,
			percentageSample: 0,
			loadDamage: false,
			itemsGang: [],
			itemSampleDamage: [],
			isEdit: false,
			itemsDataColor: [],
			itemsDataDamage: [],
			itemsDataBrix: [],
			itemSample: {},
			itemsDeleteBrix: [],
			itemsDeleteColor: [],
			itemsDeleteDamage: [],
			TypeVariety1: 0,
			DedDescription1: "",
			TypeVariety2: 0,
			DedDescription2: "",
			TypeVariety3: 0,
			DedDescription3: "",
			FfsBrixValue: "",
			Actions: "",
			itemColorVariety: [],
			HexadecimalColor: "",
			ColorDescription: "",
			ColorID: 0,
			ColorValue: null,
			ColorTextDescription: null,
			itemsData: {},
			BrxID: 0,
			headersBrix: [
				{ text: "Variedad", value: "VrtDescription" },
				{ text: "Brix", value: "FfsBrixValue", width: 70 },
				{ text: "", value: "Actions", width: 80 },
			],
			headersColor: [
				{ text: "Variedad", value: "VrtDescription" },
				{ text: "Color", value: "CbcName", width: 100 },
				{ text: "valor", value: "FcfColorValue", width: 100 },
				{ text: "", value: "Actions", width: 80 },
			],
			headerDamage: [
				{ text: "Variedad", value: "VrtDescription" },
				// { text: "Porcentaje", value: "FdfPercentage", width: 100 },
				{ text: "Cantidad Fruta", value: "FdfAmountFruit", width: 120 },
				{ text: "Tipo de defecto", value: "DfcName", width: 130 },
				{ text: "", value: "Actions", width: 80 },
			],
		}),

		methods: {
			// VALIDACIÓN GENERAL
			// isValidated() {
			//   var validate = false;
			//   let countBrix = this.itemsDataBrix.length;
			//   let countColor = this.itemsDataColor.length;

			//   if (this.objForeman == null) {
			//     this.$fun.alert("No existe Capataz Asociado", "warning");
			//     return false;
			//   }
			//   if (
			//     this.itemSample.BrxNumberJabas <= 0 ||
			//     this.itemSample.BrxNumberJabas == null
			//   ) {
			//     this.$refs.FocuesNumberJabas.error(
			//       "Debe insertar la cantidad de cajas"
			//     );
			//   } else if (countBrix <= 0) {
			//     this.$fun.alert("Debe registrar Datos de brix", "warning");
			//   } else if (countColor <= 0) {
			//     this.$fun.alert("Debe registrar Datos de Color", "warning");
			//   } else {
			//     validate = true;
			//   }
			//   return validate;
			// },

			inputColor(item) {
				this.HexadecimalColor = item ? item.CbcColor : "";
				this.ColorDescription = item ? item.CbcName : "";
				this.ColorID = item ? item.CbcID : "";
				this.ColorValue = item ? item.CbcValue : "";
			},

			inputCaliber(item) {
				console.log("valor de la variedad", item);
				this.TypeVariety1 = item ? item.VrtID : "";
				this.DedDescription1 = item ? item.VrtName : "";

				this.inputCaliberTwo(item)
			},

			inputDamage(item){
				this.TypeDefects = item ? item.DfcID : "";
				this.TypeDefectsName = item ? item.DfcName : "";  
    		},

			inputCaliberTwo(item) {
				this.TypeVariety2 = item ? item.VrtID : "";
				this.DedDescription2 = item ? item.VrtName : "";
				// Llamamos al color dependiendo la variedad
				this.getColorVariety(this.TypeVariety2);
			},

			inputVariety3(item) {
				this.TypeVariety3 = item ? item.VrtID : "";
				this.DedDescription3 = item ? item.VrtName : "";
			},

			clickDeleteBrix(item) {
				/* console.log("datos", item);
				this.itemsDataBrix = this.itemsDataBrix.filter(
					(x) => x.FfsBrixValue != item.FfsBrixValue
				);
				item.SecStatus = 0;
				if (this.isEdit) {
					this.itemsDeleteBrix.push(item);
				} */

				if (this.itemsDataBrix.length > 0) {
					if (item.FbfID > 0) {
						this.itemsDeleteBrix.push(
							this.itemsDataBrix.filter(x => x.Line == item.Line)[0],
						);
						this.itemsDeleteBrix.forEach(element => {
							element.SecStatus = 0;
						});
					}

					this.itemsDataBrix = this.itemsDataBrix.filter(
						x => x.Line != item.Line,
					);
					this.orderDetailsBrix();
				}
			},

			clickDeleteColor(item) {
				/* console.log("datos color", item);
				this.itemsDataColor = this.itemsDataColor.filter(
					(x) => x.BxcColorHexadecimal != item.BxcColorHexadecimal
				);
				item.SecStatus = 0;
				if (this.isEdit) this.itemsDeleteColor.push(item); */

				if (this.itemsDataColor.length > 0) {
					if (item.FcfID > 0) {
						this.itemsDeleteColor.push(
							this.itemsDataColor.filter(x => x.Line == item.Line)[0],
						);
						this.itemsDeleteColor.forEach(element => {
							element.SecStatus = 0;
						});
					}

					this.itemsDataColor = this.itemsDataColor.filter(
						x => x.Line != item.Line,
					);
					this.orderDetailsColor();
				}
			},

			clickDeleteDamage(item) {
				/* console.log("Datos daño", item);
				this.itemsDataDamage = this.itemsDataDamage.filter(
					(x) => x.TypeDefects != item.TypeDefects
				);
				item.SecStatus = 0;
				if (this.isEdit) this.itemsDeleteDamage.push(item); */

				if (this.itemsDataDamage.length > 0) {
					if (item.FdfID > 0) {
						this.itemsDeleteDamage.push(
							this.itemsDataDamage.filter(x => x.Line == item.Line)[0],
						);
						this.itemsDeleteDamage.forEach(element => {
							element.SecStatus = 0;
						});
					}

					this.itemsDataDamage = this.itemsDataDamage.filter(
						x => x.Line != item.Line,
					);
					this.orderDetailsDamage();
				}
			},

			orderDetailsBrix() {
				let i = 0;
				this.itemsDataBrix.map(e => {
					i++;
					e.Line = i;
				});
			},

			orderDetailsColor() {
				let i = 0;
				this.itemsDataColor.map(e => {
					i++;
					e.Line = i;
				});
			},

			orderDetailsDamage() {
				let i = 0;
				this.itemsDataDamage.map(e => {
					i++;
					e.Line = i;
				});
			},

			addBrix() {

				if(this.itemHead.FfsNumberJabas <= 0){
					this.$fun.alert("Error, ingrese la cantidad de unidades  a muestrear", "warning");
					return;
				}

				if(this.itemsDataBrix.length + 1 > this.itemHead.FfsNumberJabas){
					this.$fun.alert("Error, No puede superar la cantidad de unidades a muestrear", "warning");
					return;
				}


				if (this.FfsBrixValue.trim() == "") {
					this.$refs.focusBrixValue.error("Debe ingresar un Brix valido");
					return;
				}

				if (this.FfsBrixValue <= 0) {
					this.$refs.focusBrixValue.error("Debe ingresar un Brix valido");
					return;
				} 
				else {

					if (
						this.FfsBrixValue >= this.minBRIX &&
						this.FfsBrixValue <= this.maxBRIX
					) {

						let itemsBrix = {};
						itemsBrix.FfsID = this.FfsID ? this.FfsID : 0;
						itemsBrix.VrtID = this.TypeVariety1;
						itemsBrix.FfsBrixValue = this.FfsBrixValue;
						itemsBrix.VrtDescription = this.DedDescription1;
						itemsBrix.UsrCreateID = this.$fun.getUserID();
						itemsBrix.UsrUpdateID = this.$fun.getUserID();
						itemsBrix.Line = this.itemsDataBrix.length + 1;

						
						this.itemsDataBrix.push(itemsBrix);

						// this.addColor()

						this.orderDetailsBrix()

						console.log("datos del brix", this.itemsDataBrix);

						this.FfsBrixValue = "";
						this.$refs.focusBrixValue.focus();
					} else {
						this.$fun.alert(
							"El valor de BRIX fuera del rango " +
								this.minBRIX +
								" - " +
								this.maxBRIX,
							"warning"
						);
					}
				}
			},

			addDamage() {

				let itemsDamage = {};


				if(this.itemDamage.FdfAmountFruit <= 0 || this.itemDamage.FdfAmountFruit == null){
					this.$refs.quantityDamage.error("Error, Ingrese cantidad de fruta")
					//this.$fun.alert("Error, cantidad de fruta", "warning");
					return;
				}

				if(this.itemDamage.ObjTypeDefects == null) {
					this.$fun.alert("Seleccione Tipo de defecto", "warning");
					return;
				}

				itemsDamage.FfsID = this.FfsID ? this.FfsID : 0;
				itemsDamage.VrtID = this.TypeVariety1;
				itemsDamage.VrtDescription = this.DedDescription1;
				itemsDamage.DfcID = this.TypeDefects;
      	itemsDamage.DfcName = this.TypeDefectsName;
				itemsDamage.FdfPercentage = this.itemDamage.FdfPercentage;
				itemsDamage.FdfAmountFruit = this.itemDamage.FdfAmountFruit;
				itemsDamage.UsrCreateID = this.$fun.getUserID();
				itemsDamage.UsrUpdateID = this.$fun.getUserID();

				this.itemsDataDamage.push(itemsDamage);

				this.orderDetailsDamage()
				this.itemDamage = {};

				this.$refs.quantityDamage.focus()
			},

			addAll(){
				this.addBrix()
				this.addColor()
			},

			addColor() {

				if(this.itemHead.FfsNumberJabas <= 0){
					this.$fun.alert("Error, ingrese la cantidad de unidades  a muestrear", "warning");
					return;
				}

				if(this.itemsDataColor.length + 1 > this.itemHead.FfsNumberJabas){
					this.$fun.alert("Error, No puede superar la cantidad de unidades a muestrear", "warning");
					return;
				}

				// if(this.FcfColorValueData <= 0) {
				// 	this.$refs.FocuesFfsSamplingWeight.error("Debe ingresar peso de muestra valido");
				// 	return;
				// }

				if (this.ColorTextDescription == null) {
					this.$fun.alert("Debe seleccionar un color", "warning");
				} else {

					let itemsColor = {};
					itemsColor.FfsID = this.FfsID ? this.FfsID : 0;
					itemsColor.VrtID = this.TypeVariety2;
					itemsColor.FcfColorValue = this.ColorValue;
					itemsColor.VrtDescription = this.DedDescription2;
					itemsColor.Line = this.itemsDataColor.length + 1;
					itemsColor.CbcName = this.ColorDescription;
					itemsColor.CbcID = this.ColorID;
					itemsColor.UsrCreateID = this.$fun.getUserID();
					itemsColor.CbcColor = this.HexadecimalColor;

					this.itemsDataColor.push(itemsColor);

					this.FcfColorValueData = "";

					this.orderDetailsColor()

					console.log("Datos del color", this.itemsDataColor);

					this.ColorTextDescription =
						this.itemColorVariety.length > 0
							? this.itemColorVariety[0]
							: null;
					// this.HexadecimalColor = "EEEE";
				}
			},

			uploadFilePhoto() {
				if (this.photoSampling != null) {
					var formData = new FormData();
					formData.append("file", this.photoSampling);
					_sHelper
						.uploadfile(1, this.$fun.getUserID(), formData, 10)
						.then((resp) => {
							if (resp.status == 200) {
								this.photoSampling = [];
								return;
							}
						});
				} else {
					this.$fun.alert("Sin archivo para subir");
				}
			},

			save() {

				//validacion de especificaciones tecnicas samirP
				// 1 = el lote es valido
				// 2 = el lote NO es valido
				this.itemHead.FfsIsValid = 1;

				


				let QuantityBrix = 0, countBrix = 0, averageBrix = 0;				
				this.itemsDataBrix.forEach(element => {
					QuantityBrix = (QuantityBrix + parseInt(element.FfsBrixValue));
					countBrix++;
				});    
				averageBrix = (QuantityBrix/countBrix)      
				
				let QuantityColor = 0, countColor = 0, averageColor = 0;
				this.itemsDataColor.forEach(element => {
					QuantityColor = (QuantityColor + parseInt(element.FcfColorValue));
					countColor++;
				});
				averageColor = (QuantityColor/countColor);



				 // recorremos las especificaciones tecnicas para saber sus valores
				this.itemsSpecificationsCultive.forEach(element => {
					if(element.TypEspecifications == 7){
						console.log('promedio de brix', averageBrix);
						console.log('valor minimo de brix',element.FsaMinValue);
						console.log('valor maximo de brix', element.FsaMaxValue);
						if(averageBrix < element.FsaMinValue || averageBrix < element.FsaMaxValue  ) {
							this.itemHead.FfsIsValid = 2;
						}
					}
					if(element.TypEspecifications == 3) {
						console.log('promedio de color', averageColor);
						console.log('valor minimo de color',element.FsaMinValue);
						console.log('valor maximo de color', element.FsaMaxValue);
						if(averageColor < element.FsaMinValue || averageColor < element.FsaMaxValue  ) {
							this.itemHead.FfsIsValid = 2;                            
						}
					}
				})


			
				this.DamageCultive.forEach(element => {    
					this.itemsDataDamage.forEach(element2 => {          
					if (element.DfcName == element2.DfcName) {
						console.log("element", element);
						console.log("element2", element2);
						if (element.DfcTolerance < element2.FdfAmountFruit) {
						//enviamos a la BD que no cumple con los parametros FdfPercentage
						this.itemHead.FfsIsValid = 2;						 
						} 
					}
					});
				});
				console.log("Resultado FfsIsValid", this.itemHead.FfsIsValid);
				//return;
				// if (this.isValidated()) {
				//return;
				// this.itemDamageData.push(this.itemDamage);

				if(this.itemHead.FfSsampledUnits <= 0 || this.itemHead.FfSsampledUnits == ""){
					this.$fun.alert("Advertencia, Debe ingresar unidades muestra daños");
					return;
				}


				if(this.itemsDataBrix.length == 0) {
					this.$fun.alert("Error, No existen detalles para muestreo de Brix", "warning");
					return;
				}

				if(this.itemsDataBrix.length < this.itemHead.FfsNumberJabas) {
					this.$fun.alert("Advertencia, la cantidad de muestra de Brix debe ser igual a las unidades muestreadas","warning");
					return;
				}

				if(this.itemsDataColor.length < this.itemHead.FfsNumberJabas) {
					this.$fun.alert("Advertencia, la cantidad de muestra de Color debe ser igual a las unidades muestreadas","warning");
					return;
				}

				if(this.itemsDataColor.length == 0) {
					this.$fun.alert("Error, No existen detalles para muestreo de Color", "warning");
					return;
				}

				if(this.itemsDataDamage.length == 0) {
					this.$fun.alert("Error, No existen detalles para muestreo de daños", "warning");
					return;
				}

				this.itemHead.RcfID = this.items.RcfID ? this.items.RcfID : 0;
				this.itemHead.PseID = this.items.PseID ? this.items.PseID : 0;
				this.itemHead.MtpID = this.items.MtpID; //? this.items.MtpID : 0;

				this.itemHead.FfsState = 1;
				this.itemHead.SecStatus = 1;
				this.itemHead.UsrUpdateID = this.$fun.getUserID();
				this.itemHead.UsrCreateID = this.$fun.getUserID();

				this.itemHead.FfsID = this.FfsID ? this.FfsID : 0;

				this.itemHead.FfsImage =
					this.photoSampling != null ? this.photoSampling.name : "";

				this.itemHead.DataDamageFrozen = [...this.itemsDataDamage,...this.itemsDeleteDamage];

				this.itemHead.DataBrixFrozen = [...this.itemsDataBrix,...this.itemsDeleteBrix];
				this.itemHead.DataColorFrozen = [...this.itemsDataColor, ...this.itemsDeleteColor];

				//samir no se necesitan, por eso se envian vacios
				this.itemHead.DataAcidityFrozen = [];
				this.itemHead.DataFirmnessFrozen = [];
				this.itemHead.DataTasteFrozen = [];
				this.itemHead.DataPhFrozen = [];
				this.itemHead.DataMaturityFrozen = [];
				this.itemHead.DataSizeFrozen = [];
				this.itemHead.FfsHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));;
				//this.itemHead.DataBrixFrozen.push(...this.itemsDeleteBrix,...this.itemsDeleteColor);
				//this.itemHead.DataColorFrozen.push(...this.itemsDeleteColor);
				this.itemHead.FfsPlace = 1;
				console.log("Guardar", this.itemHead);

				this.$fun.alert("Esta seguro de guardar?", "question").then((r) => {
					if (r.value) {

						_sFrozenSampling
							.save(this.itemHead, this.$fun.getUserID())
							.then((r) => {
								if (r.status == 200) {
									this.uploadFilePhoto();
									this.$fun.alert(
										"Registrado correctamente",
										"success"
									);
									this.$emit("close");

									// this.itemHead.DataDamageFrozen = [];
									// this.itemHead.DataBrixFrozen = [];
									// this.itemHead.DataColorFrozen = [];
								}
							});
					}
				});
			},

			getSample() {
				console.log("entramos a la funcion para editar");
				//  BUSCAMOS SI HAY REGISTRO DE ESE DATO EN NUESTRA TABLA DE MUESTREO
				let obj = {
					TypeShip: 2,
					MtpStatus: 0,
					RcfID: this.items.RcfID,
					PseID: this.items.RcfID,
					FfsID: 0,
					MtpID: 0,
				};

				_sFrozenSampling.sampling(obj, this.$fun.getUserID()).then((r) => {
					console.log("Datos traidos de muestreo", r.data);
				 
					if (r.data != null) {
						console.log("obtenemos el id de la cabecera", r.data.FfsID);
						// // ID DE LA CABECERA
						this.FfsID = r.data.FfsID;
						this.itemHead = r.data;
						this.itemsDataBrix = r.data.DataBrixFrozen;
						this.itemsDataColor = r.data.DataColorFrozen;
						this.itemsDataDamage = r.data.DataDamageFrozen;
						this.isEdit = true;

						this.orderDetailsColor()
						this.orderDetailsBrix()
						this.orderDetailsDamage()

					} else {
						this.FfsID = 0;
						this.itemHead = {};
						this.itemsDataBrix = [];
						this.itemsDataColor = [];
						this.isEdit = false;
						this.itemHead.FfsIsValid = 1;
					}
				});
			},

			getColorVariety(VrtID) {
				// _sReceptionFresh
				//   .GetGang({ RcfID: this.items.RcfID }, this.$fun.getUserID())
				//   .then((r) => {
				//     this.itemsGang = r.data;
				//     if (this.itemsGang.length > 0) this.objForeman = this.itemsGang[0];
				_sBrixFreshService
					.getColorForVariety(VrtID ? VrtID : 0, this.$fun.getUserID())
					.then((r) => {
						this.itemColorVariety = r.data;
						if (this.itemColorVariety.length > 0)
							this.ColorTextDescription = this.itemColorVariety[0];
						//TRAE TODOS LAS MUESTRAS SEGUN CAPATAZ
						//  this.getSample();
					});
				// });
			},

			getSpecification(cultive){

				let obj = {
					TypeCultive : cultive,
				};

				_sFrzTechnicalspecificationsByCultive.listByCultive(obj, this.$fun.getUserID())
				.then((r)=>{
					if(r.status == 200) {
						console.log('mostramos las especificaciones', r.data);
						this.itemsSpecificationsCultive = r.data;
					}
				});
    		},
		},

		created() {


			this.getSpecification(this.items.TypeCultive);

			//  OBTENEMOS LOS DAÑOS POR CULTIVO 
			if(this.items.TypeCultive != 0) {
				console.log('mostramos lo datos de itms',this.items)
				_sDamageFlawCultiveService.list(this.items, this.$fun.getUserID())
				.then((r)=>{
					if(r.status == 200){
						console.log('daños por cultivo', r.data);
						this.DamageCultive = r.data;
						// this.cultiveType = this.DamageCultive[0].TypeCultive;
					}
				});
			}	


			// OBTENEMOS EL COLOR POR TIPO DE CULTIVO
			if(this.items.TypeCultive != 0){
				_sCultiveByColorService.list(this.items, this.$fun.getUserID())
				.then((r)=>{
					if(r.status == 200){
						this.ColorCultive = r.data;
					}
				});
			}


			//OBTIENE MUESTREO SI EXISTE N

			console.log("datos de columna seleccionada", this.items);

			if (this.items.VrtID == undefined) {
				console.log("ID para la busqueda de la variedad", this.items.PseID);
				_sReceptionStorageService
					.GetDetails(
						{ PseID: this.items.PseID, PseState: 1 },
						this.$fun.getUserID()
					)
					.then((r) => {
						this.itemsDataVariety = r.data;
						if (this.itemsDataVariety.length > 0) {
							this.objVarietyBRIX = r.data[0];
							this.objVarietyColor = r.data[0];
							this.objVarietyDamage = r.data[0];

							
						}
						console.log(
							"datos que traigo con un servicio",
							this.itemsDataVariety
						);
					});
			} else {
				let itemsVariety = {};
				itemsVariety.VrtID = this.items.VrtID;
				itemsVariety.VrtName = this.items.VrtName;

				this.itemsDataVariety.push(itemsVariety);
				this.objVarietyBRIX = itemsVariety;
				this.objVarietyColor = itemsVariety;
				this.objVarietyDamage = itemsVariety;
				console.log(
					"Mostramos las variedades capturadas",
					this.itemsDataVariety
				);
			}

			let a = [];
			a.push(
				"MinimoBRIX",
				"MaximoBRIX",
				"CantidadMuestraBRIX",
				"PorcentajeMuestraLote"
			);
			_sParameter.list(a, this.$fun.getUserID()).then((r) => {
				this.maxBRIX = r.data[0];
				this.minBRIX = r.data[1];
				this.quantityBRIX = r.data[2];
				this.percentageSample = r.data[3];
			});

			// llamamos a todos datos de muestre , su cabecera  y su detalle
			this.getSample();

			

			// _sReceptionFresh
			//   .GetGang({ RcfID: this.items.RcfID }, this.$fun.getUserID())
			//   .then((r) => {
			//     this.itemsGang = r.data;
			//     if (this.itemsGang.length > 0) this.objForeman = this.itemsGang[0];
			// _sBrixFreshService
			//   .getColorForVariety(this.items.VrtID, this.$fun.getUserID())
			//   .then((r) => {
			//     this.itemColorVariety = r.data;
			//     if (this.itemColorVariety.length > 0)
			//       this.ColorTextDescription = this.itemColorVariety[0];
			//     //TRAE TODOS LAS MUESTRAS SEGUN CAPATAZ
			//     this.getSample();
			//   });
			// });

		},
	};
</script>