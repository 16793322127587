<template>
	<div>
		<v-row>
			<v-col cols="6" class="s-col-form">
				<!-- -->
				<v-card dense outlined  :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar label="1.- Información Recepción" color="#E0F7F2"></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso Guía" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.RcfWeightGuide | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<v-label>
									{{ "-" }}
								</v-label>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso Neto" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.WbbNetWeight | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<v-label>
									{{ "-" }}
								</v-label>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4 :style="colorText">
									{{ "Diferencia " + textCondition + typeCaseWeight }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.infRefDifference | numberDefaultKg }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 :style="colorText">
									{{ lotReception.infRefDifferencePercentage | numberDefault }} %
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="6" class="s-col-form">
				<!-- -->
				<v-card dense outlined v-if="typeCaseWeight == 1 || (typeCaseWeight == 0)"  :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar label="2.- Tolerancia Peso Neto - Peso Guía %" color="#E0F7F2"></s-toolbar>
					<v-card-text>
						<!---->
						<v-row >
							<v-col class="s-col-form">
								<h4>
									{{ "Tolerancia aceptada" + typeCaseWeight }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<s-text 
									v-model="AcceptedToleranceInput" 
									decimal
									:max="100"
								/>
								 
								<!-- :readonly="lotReception.RcfStatusSap == 2" -->
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.tolWeiNetGuiToleranceAcceptedCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ tolWeiNetGuiText | numberDefault }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								
								<h4 :style="colorText">
									{{ lotReception.tolWeiNetGuiDiferenceNoAcceptedPercentage | numberDefault }} %
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.tolWeiNetGuiDiferenceNoAcceptedCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-if="true">
			<v-col cols="12" class="s-col-form">
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar :label="textcase3 + ' Descuentos a favor'" color="#E0F7F2"></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "KG Total descarte (selección)" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisFavKgTotalDiscartPercentage | numberDefault }} %
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisFavKgTotalDiscartCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "kG a favor" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 style="color:#049967">
									{{ lotReception.DisFavKgFavorPercentage | numberDefault }} %
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisFavKgFavorCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "KG descarte Actualizado (descontar)" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisFavKgDiscartUpdatePercentage | numberDefault }} %
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisFavKgDiscartUpdateCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="6" class="s-col-form">
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar :label="textcase4 + ' Información Selección'" color="#E0F7F2"></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso Actualizado" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.infSelWeightUpdate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso Lavado" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.infSelWeightWash | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso Descarte a favor" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.infSelDiscartFavor | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="6" class="s-col-form" v-if="typeCaseWeight == 0 || typeCaseWeight == 1 || typeCaseWeight == 2 || typeCaseWeight == 3 || typeCaseWeight == 4">
				<!--  -->
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar :label="textcase5 + ' Tolerancia Descarte Seleccion %'" color="#E0F7F2"></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Tolerancia aceptada" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<s-text 
									v-model="AcceptedToleranceInputDiscart" 
									decimal
									
								/>
								<!-- :readonly="lotReception.RcfStatusSap == 2" -->
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.tolWeiDisSelToleranceAcceptedCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Descarte no aceptada" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 :style="colorText">
									{{ lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage | numberDefault }} %
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
<!-- typeCaseWeight == 4 -->
			<v-col cols="6" class="s-col-form" v-if="false">
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar label="A favor" color="#E0F7F2"></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "KG a favor" }}
								</h4>
							</v-col>
							
							<v-col class="s-col-form">
								<h4 :style="colorText">
									{{ lotReception.afavKgafavorPercentage | numberDefault }} %
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.afavKgafavorCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Descarte Aceptable" }}
								</h4>
							</v-col>
							
							<v-col class="s-col-form">
								<h4 :style="colorText">
									 
									<s-text 
										v-model.number="afavKgafavorPercentageInput"
										decimal
										:disabled="AfavKgafavorPercentageInput == 3 || AfavKgafavorPercentageInput == 4"
										:value="0"
									/>
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.afavKgafavorCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Descarte no aceptada" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4 :style="colorText">
									{{ lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage | numberDefault }} %
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<!-- v-if="typeCaseWeight == 3 || typeCaseWeight == 4" -->
		<v-row class="mb-5">
			<v-col cols="6" class="s-col-form">
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar :label="textcase6 + ' Descuentos totales'" color="#E0F7F2"></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Tolerancia no aceptada + Descarte no aceptada" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso a pagar" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<h4>
									{{ lotReception.DisTotWeightPay | numberDefaultKg }}
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="6" class="s-col-form">
				<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
					<s-toolbar :label="textcase7 + ' Distribución Almacenes'" color="#E0F7F2"></s-toolbar>
					<v-card-text>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso Final aceptable" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="font-weight-black text-end">
									{{ lotReception.RfsWeigth | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="success--text font-weight-black">
									{{ "Alm. 02" }} <span class="red--text font-weight-black">
														{{ this.lotReception.TypeEntryMerchandise == 3 ? " ( PAGO DIRECTO )" : "" }}
													</span>
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Merma aceptable" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="font-weight-black text-end">
									{{ lotReception.RfsWeightDiscart | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="red--text font-weight-black">
									{{ "Alm. 20" }}
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso de bolillo" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="font-weight-black text-end">
									{{ lotReception.RfsWeightBolilloc | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="info--text font-weight-black">
									{{ "Bolillo " + lotReception.PorceBolillo + "%" }} 
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso de Devolucion" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="font-weight-black text-end">
									{{ lotReception.RfsWeightDevolution | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="info--text font-weight-black">
									{{ "Devolucion" }} 
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4>
									{{ "Peso a Favor" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="font-weight-black text-end">
									{{ lotReception.RfsWeigthNotSap | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="info--text font-weight-black">
									{{ "No SAP" }} 
								</h4>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="s-col-form">
								<h4 class="grey--text font-weight-black">
									{{ "Total SAP" }}
								</h4>
							</v-col>
							<v-col class="s-col-form">
								<p class="grey--text font-weight-black text-end">
									{{ lotReception.TotalSap | numberDefaultKg }}
								</p>
							</v-col>
							<v-col class="s-col-form">
								<h4 class="grey--text font-weight-black">
									{{ "" }} 
								</h4>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: {
		lotReception: {}
	},
	data() {
		return {
			// lotReception: {},
			AcceptedToleranceInput: 0,
			AcceptedToleranceInputDiscart: 0,
			afavKgafavorPercentageInput:0,
			typeCaseWeight: 0,
			colorText: '',
			tolWeiNetGuiText: '',
			textcase3: "3.-",
			textcase4: "4.-",
			textcase5: "5.-",
			textcase6: "6.-",
			textcase7: "7.-",
			textCondition: "en Contra",
		}
	},
	// filters: {
	// 	numberDefaultKg: (value) => {
	// 		if( value == -Infinity ) return 0; 
	// 		if( value == Infinity ) return 0; 

	// 		if( isNaN(value) ) return 0; 
	// 		if( value == undefined ) return 0; 
	// 		// if( value < 0 ) return 0; 

	// 		console.log("valor tuberia", value)
	// 		return value;
	// 	}
	// },
	watch: {
		AcceptedToleranceInput() {
			this.getCalcule();

		},

		AcceptedToleranceInputDiscart() {
			this.getCalcule();
		},
		afavKgafavorPercentageInput(){
			this.getCalcule();
		},
	},
	methods: {
		getCalcule() {
			this.typeCaseWeight = 1;
			this.lotReception.RfsWeigthNotSap = 0;
			this.lotReception.RcfWeightCondition = 0;

			// this.lotReception.WbbNetWeight = parseFloat(this.lotReception.WbbNetWeight).toFixed(4)
			// this.lotReception.RcfWeightGuide = parseFloat(this.lotReception.RcfWeightGuide).toFixed(4)

			this.lotReception.infRefDifference = (this.lotReception.WbbNetWeight - this.lotReception.RcfWeightGuide).toFixed(2);
			this.lotReception.infRefDifferencePercentage = this.lotReception.RcfWeightGuide > 0
				? ((this.lotReception.infRefDifference / this.lotReception.RcfWeightGuide) * 100).toFixed(6)//0910
				: 0


				console.log({ 
					RcfWeightGuide: this.lotReception.RcfWeightGuide,
					WbbNetWeight: this.lotReception.WbbNetWeight,
					infRefDifference: this.lotReception.infRefDifference ,
					discart: this.lotReception.discart 
				})


			if (this.lotReception.RcfWeightGuide >= this.lotReception.WbbNetWeight) {
				console.log("getCalculeCase1y2", "getCalculeCase1y2")
				this.typeCaseWeight = 1;
				this.getCalculeCase1y2();

				return;
			}

			this.textcase3 = "2.-";
			this.textcase4 = "3.-";
			this.textcase5 = "4.-";
			this.textcase6 = "5.-";
			this.textcase7 = "6.-";
			this.textCondition = "a Favor";
			this.lotReception.RcfWeightCondition = 1
			 
			if (this.lotReception.RcfWeightGuide < this.lotReception.WbbNetWeight && 
				parseFloat(this.lotReception.infRefDifference) > parseFloat(this.lotReception.discart)) {
				console.log("getCalculeCase4", "getCalculeCase4")

				this.typeCaseWeight = 4;
				this.getCalculeCase4();
				return;
			}
			if (this.lotReception.RcfWeightGuide < this.lotReception.WbbNetWeight && 
				parseFloat(this.lotReception.infRefDifference) <= parseFloat(this.lotReception.discart)) {
				console.log("getCalculeCase3", "getCalculeCase3")

				this.typeCaseWeight = 3;
				this.getCalculeCase3();
			}


			// this.lotReception.AcceptedToleranceInput = this.AcceptedToleranceInput;
			// this.lotReception.AcceptedToleranceInputDiscart = this.AcceptedToleranceInputDiscart;
		},

		getCalculeCase1y2() {
			//peso guía mayor al peso neto - diferencia de peso guia y neto mayor a 2% - descarte mayor a 3%
			this.typeCaseWeight = 1;
			this.colorText = 'color:red';
			this.tolWeiNetGuiText = 'Diferencia no aceptada'

			this.lotReception.infRefDifference = (this.lotReception.RcfWeightGuide - this.lotReception.WbbNetWeight).toFixed(6);
			this.lotReception.infRefDifferencePercentage = this.lotReception.RcfWeightGuide > 0
				? ((this.lotReception.infRefDifference / this.lotReception.RcfWeightGuide) * 100).toFixed(6)
				: 0

			this.lotReception.tolWeiNetGuiToleranceAcceptedPercentageInput = this.AcceptedToleranceInput;
			if (parseFloat(this.AcceptedToleranceInput) >= parseFloat(this.lotReception.infRefDifferencePercentage)) {
				this.lotReception.tolWeiNetGuiToleranceAcceptedCalculate = this.lotReception.infRefDifference;
				this.lotReception.tolWeiNetGuiDiferenceNoAcceptedPercentage = 0.00;
				this.lotReception.tolWeiNetGuiDiferenceNoAcceptedCalculate = 0.00;

				 
			}
			if (parseFloat(this.AcceptedToleranceInput) < parseFloat(this.lotReception.infRefDifferencePercentage)) {
				this.lotReception.tolWeiNetGuiToleranceAcceptedCalculate = (this.lotReception.RcfWeightGuide * (this.AcceptedToleranceInput / 100)).toFixed(2);
				this.lotReception.tolWeiNetGuiDiferenceNoAcceptedPercentage = (this.lotReception.infRefDifferencePercentage - this.AcceptedToleranceInput).toFixed(2);
				this.lotReception.tolWeiNetGuiDiferenceNoAcceptedCalculate = (this.lotReception.infRefDifference - this.lotReception.tolWeiNetGuiToleranceAcceptedCalculate).toFixed(2);
				
				 
			}

			this.lotReception.infSelWeightUpdate = (this.lotReception.RcfWeightGuide - this.lotReception.tolWeiNetGuiDiferenceNoAcceptedCalculate).toFixed(2);
			this.lotReception.infSelWeightWash = (this.lotReception.WbbNetWeight - this.lotReception.discart - this.lotReception.RfsWeightDevolution - this.lotReception.RfsWeightBolillo).toFixed(2);

			this.lotReception.tolWeiDisSelToleranceAcceptedPercentageInput = this.AcceptedToleranceInputDiscart;

			// console.log("this.AcceptedToleranceInputDiscart", this.AcceptedToleranceInputDiscart)
			// console.log("this.this.lotReception.discartPercentage", this.lotReception.discartPercentage)


			//let percenteadisc = (this.lotReception.discart / this.lotReception.RcfWeightGuide * 100).toFixed(2)//0910
			let percenteadisc = (this.lotReception.discart / (this.lotReception.RcfWeightGuide -  this.lotReception.RfsWeightDevolution) * 100).toFixed(2)//1206

			// if (parseFloat(this.AcceptedToleranceInputDiscart) >= parseFloat(this.lotReception.discartPercentage)) {
			if (parseFloat(this.AcceptedToleranceInputDiscart) >= parseFloat(percenteadisc)) {
				//samir
				console.log({ AcceptedToleranceInputDiscart: this.AcceptedToleranceInputDiscart })
				console.log({ discartPercentage: this.lotReception.discartPercentage })
				console.log("1")
				
				//case1
				this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = this.lotReception.discart;////.DisFavKgDiscartUpdateCalculate
				this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = 0.00;
				this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = 0.00;


				this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = percenteadisc;//SAMIR
				//this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.AcceptedToleranceInputDiscart;//SAMIR
			}
			if (parseFloat(this.AcceptedToleranceInputDiscart) < parseFloat(percenteadisc)) {
				//samir
				console.log("2")
				this.lotReception.tolWeiDisSelToleranceAcceptedCalculate =
				 ((this.lotReception.RcfWeightGuide - this.lotReception.RfsWeightDevolution) * (this.AcceptedToleranceInputDiscart / 100)).toFixed(2);//case1 0910 WbbNetWeight 
  
				// this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = (this.lotReception.discartPercentage - this.AcceptedToleranceInputDiscart).toFixed(4);
				this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = (percenteadisc - this.AcceptedToleranceInputDiscart).toFixed(6);

				this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = (this.lotReception.discart - this.lotReception.tolWeiDisSelToleranceAcceptedCalculate).toFixed(4);

				this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage ?? 0;
				this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate ?? 0;

				this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.AcceptedToleranceInputDiscart;//SAMIR
			}

			this.lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted = (parseFloat(this.lotReception.tolWeiNetGuiDiferenceNoAcceptedCalculate) + parseFloat(this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate)).toFixed(2);
			this.lotReception.DisTotWeightPay = (this.lotReception.RcfWeightGuide - this.lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted).toFixed(2) ;

			//DISTRIBUCION DE ALMACENES
			this.lotReception.RfsWeightDiscart = (parseFloat(this.lotReception.tolWeiDisSelToleranceAcceptedCalculate) + parseFloat(this.lotReception.tolWeiNetGuiToleranceAcceptedCalculate)).toFixed(2);
			this.lotReception.RfsWeigth = (parseFloat(this.lotReception.DisTotWeightPay) - parseFloat(this.lotReception.RfsWeightDiscart) 
			- parseFloat(this.lotReception.RfsWeightBolillo) - parseFloat(this.lotReception.RfsWeightDevolution)).toFixed(2);
			
			//VALIDAR SI ES PAGOS DIRECTOS

			let amountProc = this.lotReception.RfsWeigth;
			let amountDisc = this.lotReception.RfsWeightDiscart;

			console.log("CASO 1" + this.lotReception.TypeEntryMerchandise)
			if (this.lotReception.TypeEntryMerchandise == 3) {
				this.lotReception.RfsWeigth = amountDisc;
				this.lotReception.RfsWeightDiscart = amountProc;
			}
			this.lotReception.AcceptedToleranceInput = this.AcceptedToleranceInput;
			this.lotReception.AcceptedToleranceInputDiscart = this.AcceptedToleranceInputDiscart;

			this.lotReception.TotalSap = parseFloat(this.lotReception.RfsWeigth) + parseFloat(this.lotReception.RfsWeightDiscart) + parseFloat(this.lotReception.RfsWeightBolillo);
			console.log("this.lotReception.TotalSap" , this.lotReception.TotalSap)

			this.lotReception.PorceBolillo = 0;
			if (this.lotReception.RfsWeightBolillo > 0 && this.lotReception.WbbNetWeight) {
				
				this.lotReception.PorceBolillo = ((parseFloat(this.lotReception.RfsWeightBolillo)* 100)/parseFloat(this.lotReception.WbbNetWeight)).toFixed(2)
			}
			this.lotReception.RfsWeightBolilloc = this.lotReception.RfsWeightBolillo;
		},

		getCalculeCase3() {
			//peso guía mayor al peso neto - diferencia de peso guia y neto menor a 2% - descarte menor a 3%
			this.typeCaseWeight = 3;
			this.colorText = 'color:green';
			this.tolWeiNetGuiText = 'KG a favor';

			this.lotReception.infRefDifference = (this.lotReception.WbbNetWeight - this.lotReception.RcfWeightGuide).toFixed(2);
			this.lotReception.infRefDifferencePercentage = this.lotReception.RcfWeightGuide > 0
				? ((this.lotReception.infRefDifference / this.lotReception.RcfWeightGuide) * 100).toFixed(2)
				: 0

			this.lotReception.tolWeiNetGuiToleranceAcceptedPercentageInput = this.AcceptedToleranceInput;
			if (parseFloat(this.AcceptedToleranceInput) >= parseFloat(this.lotReception.infRefDifferencePercentage)) {
				this.lotReception.tolWeiNetGuiToleranceAcceptedCalculate = this.lotReception.infRefDifference;
				this.lotReception.tolWeiNetGuiDiferenceNoAcceptedPercentage = 0.00;
				this.lotReception.tolWeiNetGuiDiferenceNoAcceptedCalculate = 0.00;
			}
			if (parseFloat(this.AcceptedToleranceInput) < parseFloat(this.lotReception.infRefDifferencePercentage)) {
				this.lotReception.tolWeiNetGuiToleranceAcceptedCalculate = (this.lotReception.RcfWeightGuide * (this.AcceptedToleranceInput / 100)).toFixed(2);
				this.lotReception.tolWeiNetGuiDiferenceNoAcceptedPercentage = (this.lotReception.infRefDifferencePercentage - this.AcceptedToleranceInput).toFixed(2);
				this.lotReception.tolWeiNetGuiDiferenceNoAcceptedCalculate = (this.lotReception.infRefDifference - this.lotReception.tolWeiNetGuiToleranceAcceptedCalculate).toFixed(2);
			}

			this.lotReception.DisFavKgTotalDiscartPercentage = (this.lotReception.discart / this.lotReception.RcfWeightGuide * 100).toFixed(2)
			this.lotReception.DisFavKgTotalDiscartCalculate = this.lotReception.discart;
			this.lotReception.DisFavKgFavorPercentage = this.lotReception.infRefDifferencePercentage;
			this.lotReception.DisFavKgFavorCalculate = this.lotReception.infRefDifference;
			
			this.lotReception.DisFavKgDiscartUpdateCalculate = (this.lotReception.DisFavKgTotalDiscartCalculate - this.lotReception.DisFavKgFavorCalculate).toFixed(2);
			/* this.lotReception.DisFavKgDiscartUpdatePercentage = ((this.lotReception.DisFavKgDiscartUpdateCalculate / this.lotReception.RcfWeightGuide) * 100).toFixed(2); */
			this.lotReception.DisFavKgDiscartUpdatePercentage = 
			((this.lotReception.DisFavKgDiscartUpdateCalculate / this.lotReception.RcfWeightGuide) * 100).toFixed(2);//1610 WbbNetWeight



			this.lotReception.infSelWeightUpdate = (this.lotReception.RcfWeightGuide);
			//peso lavado
			this.lotReception.infSelWeightWash = (this.lotReception.WbbNetWeight - this.lotReception.discart - this.lotReception.RfsWeightDevolution - this.lotReception.RfsWeightBolillo);// 

			this.lotReception.tolWeiDisSelToleranceAcceptedPercentageInput = this.AcceptedToleranceInputDiscart;
			// if(this.AcceptedToleranceInputDiscart >= this.lotReception.discartPercentage) 
			console.log({
				"if": "antes de el if",
				AcceptedToleranceInputDiscart: this.AcceptedToleranceInputDiscart,
				DisFavKgDiscartUpdatePercentage: this.lotReception.DisFavKgDiscartUpdatePercentage,
			})
			//case 3
			if (parseFloat(this.AcceptedToleranceInputDiscart) >= parseFloat(this.lotReception.DisFavKgDiscartUpdatePercentage)) {
				this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = this.lotReception.DisFavKgDiscartUpdateCalculate;//.discart;
				this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = 0.00;
				this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = 0.00;
				console.log({ "if": 1 })

				this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.lotReception.DisFavKgDiscartUpdatePercentage;//SAMIR
				//this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.AcceptedToleranceInputDiscart;//SAMIR
			}
			// if(this.AcceptedToleranceInputDiscart < this.lotReception.discartPercentage) 
			// RcfWeightGuide
			if (parseFloat(this.AcceptedToleranceInputDiscart) < parseFloat(this.lotReception.DisFavKgDiscartUpdatePercentage)) {
				//aceptable
			
				this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = (this.lotReception.DisFavKgDiscartUpdatePercentage - this.AcceptedToleranceInputDiscart).toFixed(6);

				this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = 
				(((this.lotReception.RcfWeightGuide - this.lotReception.RfsWeightDevolution) * this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage) / 100).toFixed(6);// 0910WbbNetWeight 


				this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = 
					((this.lotReception.RcfWeightGuide - this.lotReception.RfsWeightDevolution) * (this.AcceptedToleranceInputDiscart / 100)).toFixed(6);//case3 0910 WbbNetWeight



				console.log(
					{ 
						"if": 2 ,
						DisFavKgDiscartUpdatePercentage: this.lotReception.DisFavKgDiscartUpdatePercentage,
						AcceptedToleranceInputDiscart:this.AcceptedToleranceInputDiscart
					}
					)

					// this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.lotReception.DisFavKgDiscartUpdatePercentage;//SAMIR
					this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.AcceptedToleranceInputDiscart;//SAMIR
			}
			// console.log({ AcceptedToleranceInputDiscart: this.AcceptedToleranceInputDiscart })
			// console.log({ discartPercentage: this.lotReception.DisFavKgDiscartUpdatePercentage })
			// console.log({ tolWeiDisSelToleranceAcceptedCalculate: this.lotReception.tolWeiDisSelToleranceAcceptedCalculate, tern: "3" })

			this.lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted = (parseFloat(this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate)).toFixed(2);
			this.lotReception.DisTotWeightPay = (this.lotReception.RcfWeightGuide - this.lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted).toFixed(2);

			console.log({
				RcfWeightGuide: this.lotReception.RcfWeightGuide,
				DisTotDifferenceNoAcceptedJoinDiscartNoAccepted: this.lotReception.DisTotDifferenceNoAcceptedJoinDiscartNoAccepted
			})

			//this.lotReception.RfsWeigthNotSap = parseFloat(this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate).toFixed(2);
			//DISTRIBUCION DE ALAMCENES
			// this.lotReception.RfsWeigthNotSap = parseFloat(this.lotReception.infRefDifference).toFixed(2);
			this.lotReception.RfsWeightDiscart = (parseFloat(this.lotReception.tolWeiDisSelToleranceAcceptedCalculate) + parseFloat(0)).toFixed(2);
			//infSelWeightWash
			this.lotReception.RfsWeigth = (this.lotReception.DisTotWeightPay - this.lotReception.RfsWeightDiscart
			- this.lotReception.RfsWeightBolillo - this.lotReception.RfsWeightDevolution 
			// - this.lotReception.infRefDifference
			).toFixed(2);//cambie //--4
			
			//this.lotReception.RfsWeigth = (parseFloat(this.lotReception.infSelWeightWash) - parseFloat(0)).toFixed(2);//antes
			console.log( "calcular DisTotWeightPay", this.lotReception.DisTotWeightPay )
			console.log( "calcular RfsWeightDiscart", this.lotReception.RfsWeightDiscart )
			console.log( "calcular discart", this.lotReception.discart )
			 
			console.log( "calcular RfsWeightBolillo", this.lotReception.RfsWeightBolillo )
			console.log( "calcular RfsWeightDevolution", this.lotReception.RfsWeightDevolution )
			
			//VALIDAR SI ES PAGOS DIRECTOS

			let amountProc = this.lotReception.RfsWeigth;
			let amountDisc = this.lotReception.RfsWeightDiscart;

			if (this.lotReception.TypeEntryMerchandise == 3) {
				this.lotReception.RfsWeigth = amountDisc;
				this.lotReception.RfsWeightDiscart = amountProc;
			}
			console.log({ RfsWeightDiscart: this.lotReception.RfsWeightDiscart })
			console.log({ RfsWeigth: this.lotReception.RfsWeigth })

			this.lotReception.AcceptedToleranceInput = this.AcceptedToleranceInput;
			this.lotReception.AcceptedToleranceInputDiscart = this.AcceptedToleranceInputDiscart;

			this.lotReception.TotalSap = parseFloat(this.lotReception.RfsWeigth) + parseFloat(this.lotReception.RfsWeightDiscart) + parseFloat(this.lotReception.RfsWeightBolillo);
			console.log("this.lotReception.TotalSap" , this.lotReception.TotalSap)
			this.lotReception.PorceBolillo = 0;
			if (this.lotReception.RfsWeightBolillo > 0 && this.lotReception.WbbNetWeight) {
				
				this.lotReception.PorceBolillo = ((parseFloat(this.lotReception.RfsWeightBolillo)* 100)/parseFloat(this.lotReception.WbbNetWeight)).toFixed(2)
			}
			this.lotReception.RfsWeightBolilloc = this.lotReception.RfsWeightBolillo;
		},

		getCalculeCase4() {
			//peso guía mayor al peso neto 
			this.typeCaseWeight = 4;
			this.colorText = 'color:green';
			this.tolWeiNetGuiText = 'KG a favor';

			this.lotReception.infRefDifference = (this.lotReception.WbbNetWeight - this.lotReception.RcfWeightGuide).toFixed(2);
			this.lotReception.infRefDifferencePercentage = this.lotReception.RcfWeightGuide > 0
				? ((this.lotReception.infRefDifference / this.lotReception.RcfWeightGuide) * 100).toFixed(2) //0910 WbbNetWeight
				: 0;

			//samir

			this.lotReception.tolWeiDisSelToleranceAcceptedPercentageInput = this.AcceptedToleranceInputDiscart;
			// if(this.AcceptedToleranceInputDiscart >= this.lotReception.discartPercentage) 
			this.lotReception.DisFavKgDiscartUpdatePercentage = this.lotReception.DisFavKgDiscartUpdatePercentage ?? 0;

			console.log({
				"if": "antes de el if",
				AcceptedToleranceInputDiscart: this.AcceptedToleranceInputDiscart,
				DisFavKgDiscartUpdatePercentage: this.lotReception.DisFavKgDiscartUpdatePercentage,
			})
			//case 4
			// DisFavKgDiscartUpdateCalculate
			// this.lotReception.DisFavKgTotalDiscartPercentage = this.lotReception.discartPercentage;
			this.lotReception.DisFavKgTotalDiscartPercentage = (this.lotReception.discart / this.lotReception.RcfWeightGuide * 100);//0910
			this.lotReception.DisFavKgTotalDiscartCalculate = this.lotReception.discart;
			this.lotReception.DisFavKgFavorPercentage = this.lotReception.infRefDifferencePercentage;
			this.lotReception.DisFavKgFavorCalculate = this.lotReception.infRefDifference;
			this.lotReception.DisFavKgDiscartUpdatePercentage = ((this.lotReception.DisFavKgFavorPercentage - this.lotReception.DisFavKgTotalDiscartPercentage)).toFixed(6);
			this.lotReception.DisFavKgDiscartUpdateCalculate = (this.lotReception.DisFavKgFavorCalculate - this.lotReception.DisFavKgTotalDiscartCalculate).toFixed(6);

			console.log("total descarte", this.lotReception.DisFavKgDiscartUpdatePercentage)
			
			if ((parseFloat(this.AcceptedToleranceInputDiscart) >= parseFloat(this.lotReception.DisFavKgDiscartUpdatePercentage))) {
				this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = this.lotReception.DisFavKgDiscartUpdateCalculate;//.discart;
				this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = 0.00;
				this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = 0.00;
				console.log({ "if": 1 })

				this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.lotReception.DisFavKgDiscartUpdatePercentage;//SAMIR
				//this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.AcceptedToleranceInputDiscart;//SAMIR
			}
			// if(this.AcceptedToleranceInputDiscart < this.lotReception.discartPercentage) 
			if ((parseFloat(this.AcceptedToleranceInputDiscart) < parseFloat(this.lotReception.DisFavKgDiscartUpdatePercentage)) || this.lotReception.DisFavKgDiscartUpdatePercentage == 0) {
				//0910
				// this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = (this.lotReception.DisFavKgDiscartUpdateCalculate * this.AcceptedToleranceInputDiscart)
				// 							/this.lotReception.DisFavKgDiscartUpdatePercentage;

				 console.log("dadsa culupu primero 1 ", this.lotReception.DisFavKgDiscartUpdateCalculate)
				 console.log("dadsa culupu primero 3", this.lotReception.AcceptedToleranceInputDiscart)
				 console.log("dadsa culupu primero 4", this.lotReception.DisFavKgDiscartUpdatePercentage)
				 console.log("dadsa culupu primero result", this.lotReception.tolWeiDisSelToleranceAcceptedCalculate)

				//este cambie
				 this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = ( (this.lotReception.RcfWeightGuide - this.lotReception.RfsWeightDevolution)
				 * (this.AcceptedToleranceInputDiscart / 100)).toFixed(6);
				//este
				 this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = parseFloat(this.lotReception.DisFavKgDiscartUpdatePercentage - this.AcceptedToleranceInputDiscart).toFixed(6);
				 console.log("dadsa culupu primero", this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage)
				 //cambiar porcentaje
				// this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = ((this.lotReception.RcfWeightGuide 
				// 		* parseFloat(this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage)) / 100).toFixed(6);
				this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate  = this.lotReception.DisFavKgDiscartUpdateCalculate - 
				this.lotReception.tolWeiDisSelToleranceAcceptedCalculate;



				// this.lotReception.tolWeiDisSelToleranceAcceptedCalculate = (this.lotReception.WbbNetWeight * (this.AcceptedToleranceInputDiscart / 100)).toFixed(2);
				// this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage = (this.lotReception.DisFavKgDiscartUpdatePercentage - this.AcceptedToleranceInputDiscart).toFixed(2);
				// this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate = ((this.lotReception.WbbNetWeight * this.lotReception.tolWeiDisSelDiferenceNoAcceptedPercentage) / 100).toFixed(2);
				
				// this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.lotReception.DisFavKgDiscartUpdatePercentage;//SAMIR
				this.lotReception.tolWeiDisSelToleranceAcceptedCalculatePR = this.AcceptedToleranceInputDiscart;//SAMIR
				console.log("dadsa", this.lotReception.RcfWeightGuide)
			}
//ultimo

			
			//case4
			this.lotReception.DisFavKgDiscartUpdatePercentage = Math.abs(this.lotReception.DisFavKgDiscartUpdatePercentage);
			this.lotReception.DisFavKgDiscartUpdateCalculate = Math.abs(this.lotReception.DisFavKgDiscartUpdateCalculate)

			console.log("dadsa culupu", this.lotReception.DisFavKgDiscartUpdatePercentage)
			this.lotReception.infSelWeightUpdate = (this.lotReception.RcfWeightGuide).toFixed(2);
			this.lotReception.infSelWeightWash = (this.lotReception.WbbNetWeight - this.lotReception.discart - this.lotReception.RfsWeightDevolution - this.lotReception.RfsWeightBolillo).toFixed(2);
			this.lotReception.infSelDiscartFavor = (this.lotReception.infRefDifference - this.lotReception.discart).toFixed(2);

			this.lotReception.infSelDiscartFavor = Math.abs(this.lotReception.infSelDiscartFavor);

			this.lotReception.afavKgafavorPercentage = ((this.lotReception.infSelDiscartFavor / this.lotReception.WbbNetWeight) * 100).toFixed(2);
			if (parseFloat(this.lotReception.afavKgafavorPercentage) < this.afavKgafavorPercentageInput) {

				console.log("sa 1")
				this.lotReception.afavKgafavorCalculate = ((this.lotReception.afavKgafavorPercentage * this.lotReception.RcfWeightGuide)/100).toFixed(2)//0910WbbNetWeight

			}else{
				this.lotReception.afavKgafavorCalculate = ((this.lotReception.afavKgafavorPercentageInput * this.lotReception.RcfWeightGuide)/100).toFixed(2)//0910WbbNetWeight
				console.log("sa 2", this.lotReception.afavKgafavorCalculate)
//DisFavKgDiscartUpdatePercentage
			}

			this.lotReception.afavKgafavorPercentageInput = this.afavKgafavorPercentageInput;
			 
			this.lotReception.RfsWeightDiscart = parseFloat(0).toFixed(2);
			this.lotReception.RfsWeigthNotSap = parseFloat(this.lotReception.tolWeiDisSelDiferenceNoAcceptedCalculate).toFixed(2);
			// this.lotReception.RfsWeightDiscart = (parseFloat(this.lotReception.tolWeiDisSelToleranceAcceptedCalculate) + parseFloat(this.lotReception.tolWeiNetGuiToleranceAcceptedCalculate)).toFixed(4);
			// this.lotReception.RfsWeigth = (parseFloat(this.lotReception.DisTotWeightPay) - parseFloat(this.lotReception.RfsWeightDiscart)).toFixed(4);
			// this.lotReception.RfsWeigth = (parseFloat(this.lotReception.infSelWeightWash)).toFixed(4);//infSelWeightUpdate


			//DISTRIBUCIONES DE ALMACENES
			this.lotReception.RfsWeigth = (parseFloat(this.lotReception.infSelWeightUpdate) + parseFloat(this.lotReception.tolWeiDisSelToleranceAcceptedCalculate) - parseFloat(this.lotReception.RfsWeightDevolution)
			- parseFloat(this.lotReception.RfsWeightBolillo)).toFixed(2);//infSelWeightUpdate
			// this.lotReception.RfsWeigthNotSap = (this.lotReception.infSelWeightWash - this.lotReception.RfsWeigth).toFixed(4);
			this.lotReception.DisTotWeightPay = this.lotReception.RfsWeigth + this.lotReception.RfsWeightDiscart;
			 
			this.lotReception.RfsWeightExceed = 0
			this.lotReception.RfsWeightBolilloc = this.lotReception.RfsWeightBolillo;
			//reseteo si es que es 0
			if(this.lotReception.RfsWeigth < 0){
				this.lotReception.RfsWeightExceed = this.lotReception.RfsWeigth * -1;
				// this.lotReception.RfsWeigth = 0;
			
				this.lotReception.RfsWeightExceed = parseFloat(this.lotReception.infSelWeightWash) + 
				parseFloat(this.lotReception.RfsWeightExceed);

				console.log("this.lotReception.RfsWeightExceed", this.lotReception.RfsWeightExceed);
				 
				this.lotReception.RfsWeigth = this.lotReception.infSelWeightWash;

				this.lotReception.RfsWeightBolilloc -= parseFloat(this.lotReception.RfsWeightExceed);
				console.log("this.lotReception.RfsWeigthNotSap", this.lotReception.RfsWeigthNotSap)
				console.log("this.lotReception.RfsWeightExceed", this.lotReception.RfsWeightExceed)

				this.lotReception.RfsWeigthNotSap = parseFloat(this.lotReception.RfsWeightExceed) + 
				parseFloat(this.lotReception.RfsWeigthNotSap);

			}
			//VALIDAR SI ES PAGOS DIRECTOS
			let amountProc = this.lotReception.RfsWeigth;
			let amountDisc = this.lotReception.RfsWeightDiscart;

			if (this.lotReception.TypeEntryMerchandise == 3) {
				this.lotReception.RfsWeigth = amountDisc;
			 	this.lotReception.RfsWeightDiscart = amountProc;
			}

			this.lotReception.AcceptedToleranceInput = this.AcceptedToleranceInput;
			this.lotReception.AcceptedToleranceInputDiscart = this.AcceptedToleranceInputDiscart;
			

			this.lotReception.TotalSap = parseFloat(this.lotReception.RfsWeigth) + parseFloat(this.lotReception.RfsWeightDiscart) + parseFloat(this.lotReception.RfsWeightBolilloc);
			console.log("this.lotReception.TotalSap" , this.lotReception.TotalSap)

			this.lotReception.PorceBolillo = 0;
			if (this.lotReception.RfsWeightBolillo > 0 && this.lotReception.WbbNetWeight) {
				
				this.lotReception.PorceBolillo = ((parseFloat(this.lotReception.RfsWeightBolillo)* 100)/parseFloat(this.lotReception.WbbNetWeight)).toFixed(2)
			}
		}

	},

	created() {
		// this.lotReception = this.lotReceptionInput;
 
		
	},
	mounted() {

		console.log( { 
			TypeWeight: this.lotReception.TypeWeight, 
			status: "mounted guide",
			AcceptedToleranceInputDiscart : this.AcceptedToleranceInput,
			AcceptedToleranceInput: this.lotReception.AcceptedToleranceInput,
			AcceptedToleranceInputDiscart: this.lotReception.AcceptedToleranceInputDiscart
		 } )
		 
		// this.AcceptedToleranceInput =  -1;
		// this.AcceptedToleranceInputDiscart = -1;
		// this.AfavKgafavorPercentageInput = -1;
		//  console.log("ads", "das")
		this.AcceptedToleranceInput =  this.lotReception.AcceptedToleranceInput ?? 0;
		this.AcceptedToleranceInputDiscart = this.lotReception.AcceptedToleranceInputDiscart ?? 0;
		this.AfavKgafavorPercentageInput = this.lotReception.AfavKgafavorPercentageInput ?? 0;
		
		this.getCalcule();
	}
}
</script>
