<template>
	<div>
		<s-toolbar
			label="Subida a SAP"
			dark
			:color="'#BAB6B5'"
			close
			save
			@save="savePre()"
			:load="lotReception.RcfStatusSap == 1 || lotReception.RcfState == 11"
			@load="save()"
			@close="closeDialogLoadSap()"
			class="mb-6"
			sync
			@sync="Sincronizar()"
			print
			@print="printPdf()"
		></s-toolbar>

		<s-select-definition
			label="tipo peso"
			:def="1377"
			@getDataInitial="getItemsTypeWeight($event)"
			hidden
		></s-select-definition>
		<!-- v-model="lotReception.TypeWeight" -->

		<v-col >
			<v-row style="margin-top : -30px">
				<v-col cols="12" lg="12" md="12" sm="12" class="s-col-form">
					<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
						<s-toolbar 
							label="Información General"
							color="#E0F7F2"
						></s-toolbar>
						<v-container>
							<v-row>
								<v-col cols="2" lg="2" md="2" sm="2" class="s-col-form">
									<s-text
										v-model="lotReception.RcfID"
										readonly
										label="N° Lote"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2" class="s-col-form" v-if="lotReception.TypeProcessingPlant != 2">
									<s-text
										v-model="lotReception.PrfYuliano"
										readonly
										label="Juliano"
									></s-text>
								</v-col> 
								<v-col cols="2" lg="2" md="2" sm="2" class="s-col-form" v-if="lotReception.TypeProcessingPlant == 2">
									<s-text
										v-model="lotReception.RcfYulianoExt"
										label="Juliano"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2" class="s-col-form">
									<s-text
										v-model="lotReception.TypeServiceName"
										readonly
										label="Tipo Servicio"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2" class="s-col-form">
									<s-text
										v-model="lotReception.TypeOriginName"
										readonly
										label="Procedencia"
									></s-text>
								</v-col>
								<v-col cols="4" lg="4" md="4" sm="4" class="s-col-form">
									<s-text
										v-model="lotReception.FagName"
										readonly
										label="Fundo"
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.TypeCropName"
										readonly
										label="Tipo Cultivo"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.TypeCultiveName"
										readonly
										label="Cultivo"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.VrtName"
										readonly
										label="Variedad"
									></s-text>
								</v-col>
								<v-col cols="2" lg="2" md="2" sm="2">
									<s-text
										v-model="lotReception.PrdCardCodeResponsable"
										readonly
										label="Cod. R.C"
									></s-text>
								</v-col>
								<v-col cols="4" lg="4" md="4" sm="12">
									<s-text
										v-model="lotReception.PrdCardNameResponsable"
										readonly
										label="Responsable Por Cobrar"
									></s-text>
								</v-col>
								<v-col
									v-if="this.$fun.hasSpecialPermission('uploadsap')"
									cols="4" lg="4" md="4" sm="12">
									<s-text
										v-model="lotReception.PrdCardNameTrue"
										readonly
										label="Productor"
									></s-text>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" lg="12" md="12" sm="12" class="s-col-form">
					<!--  -->
					<v-card dense outlined :disabled="lotReception.RcfStatusSap == 2">
						<s-toolbar 
							label="Documento de entrada"
							color="#E0F7F2"
						></s-toolbar>
						<v-container>
							<v-row>
								<v-col class="s-col-form">
									<s-select-definition
										v-model="lotReception.TypeEntryDocument"
										label="Documento entrada"
										:def="1365"
										@input="input($event)"
										@change="change($event)"
									></s-select-definition>
								</v-col>
								<v-col class="s-col-form">
									<s-date
										v-model="lotReception.DateDocument"
										label="Fecha"
									></s-date>
								</v-col>
								<v-col class="s-col-form">
									<s-text
										v-model="lotReception.Serie"
										label="Serie"
										readonly
									></s-text>
								</v-col>
								<v-col class="s-col-form">
									<s-text
										v-model="lotReception.Issue"
										label="Nro"
									></s-text>
								</v-col>
								<v-col class="s-col-form">
									<s-text
										v-model="lotReception.AmountJabasWeigh"
										readonly
										label="Cantidad Jabas"
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="2" class="s-col-form">
									<v-label>
										Descarte
									</v-label>
									<h3>
										<span>{{lotReception.discart + ' || ' }}</span>
										<span style="color:red">{{ lotReception.discartPercentage + '%'}}</span>
									</h3>
								</v-col>
								<v-col cols="10" class="s-col-form">
									<v-radio-group
										row
										hide-details=""
										v-model="TypeWeightGeneral"
									>
										<v-radio
											v-for="item in itemsTypeWeight"
											:key="item.DedValue"
											:label="item.DedDescription"
											:value="item.DedValue"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
			
			<!-- para cuando es pagado por peso guia -->
			<v-row v-if="TypeWeightGeneral == 1">
				<v-col>
					<weight-guide
						:lotReception="lotReception"
					></weight-guide>
				</v-col>
			</v-row>

			<!-- para cuando es pagado por peso lavado -->
			<v-row v-if="TypeWeightGeneral == 2">
				<v-col>
					<weight-plant
						:lotReception="lotReception"
					></weight-plant>
				</v-col>
			</v-row>

			<!-- para cuando es peso ticket -->
			<v-row v-if="TypeWeightGeneral == 3">
				<v-col>
					<weight-ticket
						:lotReception="lotReception"
					></weight-ticket>
				</v-col>
			</v-row>

			<!-- para cuando es peso lavado -->
			<v-row v-if="TypeWeightGeneral == 4">
				<v-col>
					<weight-wash
						:lotReception="lotReception"
					></weight-wash>
				</v-col>
			</v-row>
			<v-row v-if="TypeWeightGeneral == 5">
				<v-col>
					<v-card>
						<v-row class="pl-5">
							<v-col cols="12" class="red--text">
								 Esta Información es solamente informativa para el methodo de pago Otros (ya que no es permito guardar con este metodo de pago).
							</v-col>
							<v-col cols="2">
								<s-text
									label="Peso Alm. 20"
									v-model.number="weightOthers.WeightOne"
									readonly
								/>
							</v-col>
							<v-col cols="2">
								<s-text
									label="Descarte"
									v-model.number="weightOthers.WeightTwo"
									readonly
								/>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			<v-row v-if="TypeWeightGeneral == 6">
				<v-col>
					 
					<Weight-consignacion
						:lotReception="lotReception"
					></Weight-consignacion>
				</v-col>
			</v-row>
		</v-col>
		<v-dialog v-model="processingSAP" :persistent="processingSAP" width="400">
		<v-card color="primary" dark>
			<v-card-text >
			Por favor espere
			<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
			</v-card-text>
				
		</v-card>
		
	</v-dialog>
	<v-dialog v-if="dialogPdf && lotReception.RfsID > 0" v-model="dialogPdf"  
			persistent>
			<load-sap-old-pdf :referralGuideSelected="lotReception" @close="dialogPdf = false"></load-sap-old-pdf>
		</v-dialog>
	</div>
	
</template>

<script>
	import _sReceptionFrozenService from "@/services/FrozenProduction/ReceptionFrozenService.js"
	import _sParameterService from "@/services/General/ParameterService";

	import WeightGuide from './GuideTypeWeight/WeightGuide.vue';
	import WeightPlant from './GuideTypeWeight/WeightPlant.vue';
	import WeightTicket from './GuideTypeWeight/WeightTicket.vue';
	import WeightWash from './GuideTypeWeight/WeightWash.vue';
	import WeightConsignacion from './GuideTypeWeight/WeightConsignacion.vue';
	import LoadSapOldPdf from './LoadSapOldPDF.vue';

	export default {
		components: { 
			WeightGuide,
			WeightPlant,
			WeightTicket,
			WeightWash ,
			WeightConsignacion,
			LoadSapOldPdf
		},
		
		props: {
			lotReception: null,
		},

		data() {
			return {
				dialogPdf: false,
				toleranceDiscart : 0,
				toleranceDiff : 0,

				processingSAP: false,
				listPricesSap: [],
				objTypeCurrency: {},
				typeweights: [],
				ckeckWeigth: false,
				ObjTypeEntryDocument: {},
				ObjTypeEntryDocumentA: [],
				weigthSelectedReal: "",
				weigthSelectedRealreadonly: true,
				lotReceptionSave: {},
				hasToleranceDiscart : false,
				hasToleranceDiff: false,
				itemsTypeWeight : [],
				AcceptedToleranceInput : 0,
				AcceptedToleranceInputDiscart : 0,
				TypeWeightGeneral : 0,
				ToleranceMaxDifPeso : 0,
				ToleranceMaxDiscart : 0,
				weightOthers: {}
			}
		},

		watch: {
			"lotReception.ListNum"() {
				this.getPrice();
			},

			TypeWeightGeneral()
			{
				// this.lotReception.TypeWeight = this.TypeWeightGeneral;
				this.getCalcule();
			}
		},

		methods: {
			getItemsTypeWeight(items)
			{
				this.itemsTypeWeight = items;
			},

			initialize()
			{
				
				_sReceptionFrozenService
				.searchbyid(this.lotReception, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						if(resp.data != null)
						{
							this.lotReception.RfsCode = resp.data.RfsCode;
							this.lotReception.Issue = resp.data.RfsIssue;
							this.lotReception.IgvCheck = resp.data.RfsIgv;
							// this.ObjTypeEntryDocument.DedHelper = resp.data.RfsSerie;
							// this.ObjTypeEntryDocument.DedValue = resp.data.TypeEntryDocument;
							console.log("resp.data.TypeEntryDocument", resp.data.TypeEntryDocument)
							// this.weigthSelectedReal = resp.data.RfsWeigth;
							this.lotReception.RfsWeigth = resp.data.RfsWeigth;
							this.lotReception.TypeWeight = resp.data.TypeWeight;
							this.lotReception.DateDocument = resp.data.RfsDocumentDate;
							this.lotReception.TypeEntryDocument = resp.data.TypeEntryDocument;
							// this.lotReception.Serie = resp.data.Serie;
							this.lotReception.RfsID = resp.data.RfsID;
							this.lotReception.RcfYulianoExt = resp.data.RcfYulianoExt;
							if(resp.data.RfsWeightDiscart != null)
							{
								this.lotReception.discart = resp.data.RfsWeightDiscart;//quiere cambio
							}
							
							this.lotReception.AcceptedToleranceInput = resp.data.AcceptedToleranceInput;
							this.lotReception.AcceptedToleranceInputDiscart = resp.data.AcceptedToleranceInputDiscart; 
							this.lotReception.AcceptedToleranceInputDiscart = resp.data.AcceptedToleranceInputDiscart; 
							this.lotReception.AfavKgafavorPercentageInput = resp.data.AfavKgafavorPercentageInput;
							// this.lotReception = [...this.lotReception, ...resp.data];
							this.TypeWeightGeneral = this.lotReceptionSave.TypeWeight ?? 0;
							
							console.log({
								'datos que me traen de recepcion david': resp.data,
								lotReception: this.lotReception
							}) 

							this.weightOthers.WeightOne = this.lotReception.RfsWeigth ?? 0;
							this.weightOthers.WeightTwo = this.lotReception.RfsWeightDiscart ?? 0;
						}
					}
				});
			},

			getPrice()
			{
				let obj = {
					PriceList: this.lotReception.ListNum,
					TypeCultive: this.lotReception.TypeCultive,
					TypeCrop: this.lotReception.TypeCrop,
					VrtID: this.lotReception.VrtID,
				};
				_sReceptionFrozenService
				.getprice(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
					}
				})
			},

			Sincronizar(){
				
				//validar si esta en SAP	
				console.log("this.lotReception.", this.lotReception);
				if (this.lotReception.RcfID == "" || this.lotReception.RcfID == null) {
					this.$fun.alert("No se ha seleccionado Lote", "warning");
					return;
				}
				 
				this.processingSAP = true;
				_sReceptionFrozenService
					.verifLoadsap(this.lotReception, this.$fun.getUserID())
					.then(resp => {
						this.processingSAP = false;
						console.log("resp", resp);
						if(resp.status == 200){
							this.$fun.alert("Se Sincronizo Correctamente", "success")
							this.closeDialogLoadSap();
						}
					},(e) => {
						this.processingSAP = false;
						console.log("eeeeeee",  e.response.data.Message);

					} 
					) 
			},

			save() {

				if(this.lotReception.RcfStatusSap == 2)
				{
					this.$fun.alert("El lote ya esta cargado a SAP");
					return;
				}
				this.$fun.alert("¿Seguro de subir a SAP?", "question")
				.then(r => {
					if(r.value){
						
						if(this.lotReception.TypeEntryDocument == null)
						{
							this.$fun.alert("Ingrese tipo de documento de entrada");
							return;
						}
						if(this.lotReception.Serie == "" || this.lotReception.Serie == 0)
						{
							this.$fun.alert("Ingrese Serie");
							return;
						}
						if(this.lotReception.Issue == "" || this.lotReception.Issue == 0)
						{
							this.$fun.alert("Ingrese número");
							return;
						}
						if(this.lotReception.RcfWeightGuide == "" || this.lotReception.RcfWeightGuide == 0){
							this.$fun.alert("Ingrese Peso Guía");
							return;
						}
						// this.lotReception.WbbNetWeight = this.lotReception.RfsWeigth;
						// this.lotReception.discart = this.lotReception.RfsWeightDiscart;//necesita revision

						// this.lotReception.WbbNetWeight = parseFloat(this.lotReception.WbbNetWeight);
						this.lotReception.RfsWeigth = parseFloat(this.lotReception.RfsWeigth);
						// this.lotReception.discart = this.lotReception.RfsWeightDiscart;//necesita revision
						 
						this.lotReception.TypeDocument = this.ObjTypeEntryDocument.DedValue;
						/* this.lotReception.TypeCurrency = this.objTypeCurrency.DedValue;
						this.lotReception.TypeCurrencyHelper = this.objTypeCurrency.DedHelper; */
						console.log("this.lotReception.WbbNetWeight", this.$fun.getUserSAPInfo().UsrID)
						  
						_sReceptionFrozenService
						.loadsap(this.lotReception, this.$fun.getUserSAPInfo().UsrID, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Subido Correctamente", "success")
							}
						})
					}
				})

			},

			closeDialogLoadSap()
			{
				this.$emit("closeDialogLoadSap");
			},

			getDataInitialTypeWeigths(items)
			{
				this.typeweights = items;
			},

			input(item)
			{
				this.ObjTypeEntryDocument = this.ObjTypeEntryDocumentA[item - 1];
				console.log("change type guide", this.ObjTypeEntryDocument)
				if (this.ObjTypeEntryDocument) {
					
					this.lotReception.Serie = this.ObjTypeEntryDocument.DedHelper ?? 0;
				}
				  
				
				// console.log("input type guide", item)
			  
			},
			change(items)
			{
				this.ObjTypeEntryDocumentA = items;
				// this.lotReception.Serie = item.DedHelper;
			}, 
			changeTypeWeight(lotReception)
			{
				// this.weigthSelectedRealreadonly = true;
				// let discartOld = lotReception.discart;
				
				// if(lotReception.TypeWeight == 1)
				// {
				// 	this.weigthSelectedReal = lotReception.RcfWeightGuide;
				// }
				// if(lotReception.TypeWeight == 2)
				// {
				// 	this.weigthSelectedReal = lotReception.WbbNetWeight;
				// }
				// if(lotReception.TypeWeight == 3)
				// {
				// 	this.weigthSelectedReal = lotReception.RcfWeightTicket;
				// }
				// if(lotReception.TypeWeight == 4)
				// {
				// 	this.weigthSelectedReal = lotReception.RcfWeightWash;
				// }
				// if(lotReception.TypeWeight == 5)
				// {
				// 	this.weigthSelectedReal = 0;
				// 	this.weigthSelectedReal = this.weigthSelectedReal;
				// 	this.weigthSelectedRealreadonly = false;
				// }
 
			}, 
			inputTolerance(value)
			{
				this.lotReception.percentageDiscart = (this.lotReception.discart*(value/100)).toFixed(2);
			}, 
			inputDiff(value)
			{
				this.lotReception.percentageReturn = ((this.lotReception.RcfWeightGuide - this.lotReception.WbbNetWeight)*(value/100)).toFixed(2);
			}, 
			savePre()
			{
				if(this.lotReception.RcfStatusSap == 2)
				{
					this.$fun.alert("El lote ya esta cargado a SAP");
					return;
				}
				if ( !this.lotReception.RfsWeigth) {
					this.$fun.alert("No se puede guardar peso a pagar en valor 0");
					return;
				}

				if (this.TypeWeightGeneral == 2 || this.TypeWeightGeneral == 4) {
					this.lotReceptionSave.AcceptedToleranceInput = 0;
					 
				}
				if (this.TypeWeightGeneral == 1 || this.TypeWeightGeneral == 3) {
					 
					// if (!this.lotReception.AcceptedToleranceInput  && this.lotReception.AcceptedToleranceInputDiscart != 0) {
					// 	this.$fun.alert("La tolerancia debe ser un numero valido" +  this.lotReception.AcceptedToleranceInput , "warning");
					// 	return;
					// }
				}
				console.log({ AcceptedToleranceInputDiscart: this.lotReception.AcceptedToleranceInputDiscart })
				if ( !this.lotReception.AcceptedToleranceInputDiscart && this.lotReception.AcceptedToleranceInputDiscart != 0 && this.TypeWeightGeneral != 6) {
					this.$fun.alert("La tolerancia debe ser un numero valido2");
					return;
				}
				 
				
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value)
					{
						if(this.ObjTypeEntryDocument.DedValue == undefined)
						{
							this.$fun.alert("Seleccione el documento de entrada", "warning");
							return;
						}
						if(this.lotReception.Serie == null || this.lotReception.Serie == "")
						{
							this.$fun.alert("Ingrese la serie", "warning");
							return;
						}
						if(this.lotReception.Issue == null || this.lotReception.Issue == "")
						{
							this.$fun.alert("Ingrese el número", "warning");
							return;
						}

						this.lotReceptionSave = this.lotReception;
						this.lotReceptionSave.RfsDocumentDate = this.lotReception.DateDocument;
						this.lotReceptionSave.TypeEntryDocument = this.ObjTypeEntryDocument.DedValue;
						this.lotReceptionSave.RfsSerie = this.lotReception.Serie;
						this.lotReceptionSave.RfsIssue = this.lotReception.Issue;
						this.lotReceptionSave.RfsIgv = this.lotReception.IgvCheck;
						// this.lotReceptionSave.RfsWeigth	 = this.weigthSelectedReal;
						this.lotReceptionSave.UsrCreateID	 = this.$fun.getUserID();
						this.lotReceptionSave.UsrUpdateID	 = this.$fun.getUserID();
						this.lotReceptionSave.RfsWeigthGuide = this.lotReception.RcfWeightGuide;
						// this.lotReceptionSave.RfsWeightDiscart = this.lotReception.RfsWeightDiscart;
						this.lotReceptionSave.RcfYulianoExt = this.lotReception.RcfYulianoExt;
						this.lotReceptionSave.TypeWeight = this.TypeWeightGeneral;
						
						
						 	
						console.log(' this.lotReceptionSave', this.lotReceptionSave)
						console.log(' TypeWeightGeneral mode de pago', this.TypeWeightGeneral)
						 
						_sReceptionFrozenService
						.saveuploadsap(this.lotReceptionSave, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Guardado Correctamente", "success");
							}
						})
					}
				})
				
			}, 
			getCalcule()
			{
				this.lotReception.TypeWeight = 0;
				//cambio para restar tambien el bolillo
				console.log("this.lotReception.RfsWeightBolillo", this.lotReception.RfsWeightBolillo);
				var weightBolillo = isNaN(this.lotReception.RfsWeightBolillo) ? 0 : this.lotReception.RfsWeightBolillo;
				var weightDevolution = isNaN(this.lotReception.RfsWeightDevolution) ? 0 : this.lotReception.RfsWeightDevolution;
				this.lotReception.discart = (this.lotReception.WbbNetWeight - this.lotReception.RcfWeightWash -  weightBolillo - weightDevolution).toFixed(2);//4

				this.lotReception.discart = Math.abs(this.lotReception.discart);

				this.lotReception.discartPercentage = ((this.lotReception.discart/(this.lotReception.WbbNetWeight - weightBolillo - weightDevolution))*100).toFixed(1)//4

				console.log('calculos ', this.lotReception);

				// this.lotReception.RfsWeightExceed = this.lotReception.RcfWeightGuide - this.lotReception.weightDevolution;
			}, 
			getParameterTolerance()
			{
				let parametersString = "ToleranciaAceptableMaximaDifPeso, ToleranciaAceptableMaximaDescart";

				_sParameterService
				.searchlist(parametersString, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.ToleranceMaxDifPeso = resp.data[0].PrmValue;
						this.ToleranceMaxDiscart = resp.data[1].PrmValue;
					}
				});
			},
			printPdf(){
				this.dialogPdf = true;
			},
		},

		created () {
			
		},

		mounted () {
			console.log("lotReception", this.lotReception)
			this.initialize();
			this.getParameterTolerance();
			this.getDataInitialTypeWeigths();
			//this.lotReception.discart = (this.lotReception.WbbNetWeight - this.lotReception.RcfWeightWash).toFixed(2);
			this.getCalcule();
			/* this.lotReception.percentageDiscart = ((this.lotReception.discart / this.lotReception.WbbNetWeight) * 100).toFixed(2);
			this.lotReception.percentageReturn = ((this.lotReception.percentageReturn / this.lotReception.WbbNetWeight) * 100).toFixed(2); */
			this.lotReceptionSave = this.lotReception;

			console.log({ lotReceptionSave: this.lotReceptionSave, TypeWeight: this.lotReceptionSave.TypeWeight})
			 

			// this.TypeWeightGeneral = this.lotReceptionSave.TypeWeight ?? 0;

			//cuando sea otros por ahora ya que se ha subido fruta como otros
			
		},
	}
</script>