<template>
	<v-container>
		<div>
			<s-crud
				load
				@load="openDialogLoadSap($event)"
				:filter="filter"
				:config="config"
				edit
				pdf
				@pdf="downloadDamage()"
				@save="save($event)"
				@EditEvent="editEvent()"
				height="auto"
				@rowSelected="rowSelected($event)"
				search-input
				ref="gridReceptionFresh"
				:title="title"
				no-full
				@getFullData="getFullData($event)"
				btnsearch
			>
				
				<template v-slot:options>
					<v-tooltip bottom="">
						<template v-slot:activator="{ on }">
							<v-btn
								v-on="on"
								text
								black 
								@click="openJoinLotTransfer()"
								small>
								<i class="fas fa-boxes"></i>
							</v-btn>
						</template>
						<span>Unir Lotes</span>
					</v-tooltip>

					<v-tooltip bottom="">
						<template v-slot:activator="{ on }">
							<v-btn
								v-on="on"
								text
								black 
								@click="openReceivedResume()"
								small>

								<i class="fas fa-eye"></i>
							</v-btn>
						</template>
						<span>Resumen Recepcionado</span>
					</v-tooltip>

					<div>
						<v-btn
							v-if="$fun.isAdmin()"
							x-small 
							color="error" 
							@click="cancelLot()"
						>	
							<i class="far fa-stop-circle"></i>Cancelar Lote
						</v-btn>
					</div>

					
				</template>

				<template v-slot:filter>
					<v-container>
						<v-row style="margin-left: 5px" justify="center">
							<v-col cols="6" md="6" lg="3" class="pb-0 pt-1">
								<s-date
									label="Fecha Inicio"
									v-model="filter.cDateInitial"
								></s-date>
							</v-col>
							<v-col cols="6" md="6" lg="3" class="pb-0 pt-1">
								<s-date
									label="Fecha Fin"
									v-model="filter.cDateFin"
								></s-date>
							</v-col>

							<v-col
								cols="6"
								md="6"
								lg="3"
								v-if="filter.filterAll == 0"
								class="pb-0 pt-1"
							>
								<s-select-definition
									clearable
									:def="1172"
									label="Tipo Cultivo"
									v-model="filter.TypeCrop"
								/>
							</v-col>
							<v-col
								cols="6"
								md="6"
								lg="3"
								v-if="filter.filterAll == 0"
								class="pb-0 pt-1"
							>
								<s-select-definition
									clearable
									:def="1173"
									label="Cultivo"
									v-model="filter.TypeCultive"
								/>
							</v-col>

							<v-col
								cols="6"
								md="6"
								lg="3"
								v-if="filter.filterAll == 0"
								class="pb-0 pt-1"
							>
								<s-select-variety
									clearable
									:cultiveID="filter.TypeCultive"
									label="Variedad"
									full
									v-model="filter.VrtID"
								/>
							</v-col>

							<v-col
								cols="6"
								md="6"
								lg="3"
								v-if="filter.filterAll == 0"
								class="pb-0 pt-1"
							>
								<s-select-definition
									clearable
									label="Tipo de Servicio"
									:def="1171"
									v-model="filter.cTypeServiceID"
								></s-select-definition>
							</v-col>

							<v-col
								cols="6"
								md="6"
								lg="3"
								v-if="filter.filterAll == 0"
								class="pb-0 pt-1"
							>
								<s-select-type-certification
									clearable
									label="Certificacion"
									full
									v-model="filter.TcrID"
								></s-select-type-certification>
							</v-col>

							<v-col
								cols="6"
								md="6"
								lg="3"
								v-if="filter.filterAll == 0"
								class="pb-0 pt-1"
							>
								<s-select-definition
									:def="1230"
									label="Planta Procesadora"
									v-model="filter.TypeProcessingPlant"
									clearable
								/>
							</v-col>

							<!-- <v-col cols="6" md="6" lg="3">
								<s-switch
									label="Filtrar todos"
									v-model="filter.filterAll"
								></s-switch>
							</v-col> -->
						</v-row>
						<v-row style="margin-left: 5px">
							<v-col cols="12" lg="3" md="3" class="pt-1">
								<s-select-definition
									clearable
									label="Estado"
									:def="1256"
									v-model="filter.TypeState"
								></s-select-definition>
							</v-col>
							<v-spacer></v-spacer>
							<v-col cols="6" lg="2" class="mt-3 pb-0">
								<v-btn
									v-if="selected.TypeProcessingPlant == 2"
									style="color: white"
									small
									color="warning"
									rounded
									block
									@click="openEnterMaquila()"
									:disabled="!existsData"
								>
									Ingreso Data
								</v-btn>
							</v-col>
							<v-col cols="6" lg="2" class="mt-3 pb-0">
								<v-btn
									style="color: white"
									small
									color="info"
									rounded
									block
									@click="WeighMaterial()"
								>
									Pesado
								</v-btn>
							</v-col>
							<v-col cols="6" lg="2" class="mt-3 pb-0">
								<v-btn
									@click="ModalBrix()"
									style="color: white"
									small
									rounded
									:disabled="!existsData"
									block
									color="success"
								>
									Muestreo
								</v-btn>
							</v-col>

							<!-- <v-col cols="6" sm="2" v-if="ship == 2">
								<v-btn 
									@click="dialogDecrease = true"
									style="color: white"
									small
									rounded
									block
									color="warning"
								>
									Merma
								</v-btn>
							</v-col> -->
						</v-row>
					</v-container>
				</template>

				<template v-slot:Join="{ row }">
						<!--  &&  && row.RcfStatusSap == 2-->
					<v-checkbox
						 v-if="(row.TypeShip == 4 && row.RcfState == 1) ||  (row.TypeEntryMerchandise == 3 && row.RcfState != 12)"
						v-model="JoinArray"
						:value="row.RcfID"
						@click="addLotTransfer(row)"
					></v-checkbox>
				</template>
				
				<template v-slot:genyuliano="{ row }">
					<v-btn
						x-small
						color="info"
						block
						outlined
						@click="clickGenerateYuliano(row)"
					>
						<i class="fas fa-yen-sign"></i>
					</v-btn>
				</template>

				<template v-slot:ViewCert="{ row }">
					<v-btn
						x-small
						color="info"
						block
						outlined
						@click="clickView(row)"
					>
						<v-icon style="font-size: 16px !important">fas fa-eye</v-icon>
					</v-btn>
				</template>

				<template scope="props">
					<alert-supplies :FltID="objLot.FltID"></alert-supplies>


					<v-card :disabled="disabledAll">
						<!-- Inicio Datos Recepcion Vigilancia -->
						<v-row>
							<div class="col-md-12 col-sm-12">
								<v-col cols="12" md="12">
									<v-row>
										<v-col cols="12" md="12">
											<v-toolbar
												dense
												dark
												:color="'#BAB6B5'"
												style=" height: 28px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px;"
											>
												<v-toolbar-title style="height: 38px"
													>Datos Recepcion
													Vigilancia</v-toolbar-title
												>
											</v-toolbar>
										</v-col>
										<v-col cols="12" md="6">
											<p>
												Fecha Recepcion:
												{{
													props.item.RcfDateReceptionSurveillance
												}}
											</p>
										</v-col>
										<v-col cols="6" md="3">
											<p>Planta Procesadora:</p>
										</v-col>
										<v-col cols="6" md="3">
											<s-select-definition
												clearable
												:def="1230"
												v-model="props.item.TypeProcessingPlant"
											/>
										</v-col>
										<v-col cols="12" md="6" style="margin-top: -35px">
											<p>Nro. Recepcion: {{ props.item.RcsID }}</p>
										</v-col>
										<v-col
											cols="6"
											md="3"
											style="margin-top: -30px"
											v-if="props.item.TypeProcessingPlant == 2"
										>
											<p>Empacadora:</p>
										</v-col>
										<v-col
											cols="6"
											md="3"
											style="margin-top: -30px"
											v-if="props.item.TypeProcessingPlant == 2"
										>
											<s-select-definition
												:def="1226"
												v-model="props.item.TypeBaler"
											/>
										</v-col>
									</v-row>
								</v-col>
							</div>
						</v-row>
						<!-- Fin Datos Recepcion Vigilancia -->

						<!-- Inicio Fundo || Vehiculos  -->
						<v-row style="margin-top: -30px; margin-bottom: -40px">
							<!-- Inicio Fundo/User -->
							<div class="col-lg-12 col-md-12">
								<v-card outlined rounded elevation="2" dense>
									<v-card-text>
										<b>Datos de Fundo - </b>
										<b>{{ objLot.FltCodSenasa }}</b>
									</v-card-text>
									<v-card-actions>
										<v-row>
											<v-col lg="2" md="2" cols="12">
												<s-search-lot-by-cod-senasa
													@searchSenasa="searchSenasa($event)"
												/>
											</v-col>

											<v-col cols="12" lg="4" md="4">
												<s-text
													label="Productor"
													v-model="objLot.PrdText"
													readonly
												></s-text>
											</v-col>

											<v-col cols="12" lg="4" md="4">
												<s-text
													readonly
													label="Fundo"
													v-model="objLot.FagName"
												></s-text>
											</v-col>

											<v-col cols="12" lg="2" md="2">
												<s-text
													readonly
													label="Lote"
													v-model="objLot.FltName"
												></s-text>
											</v-col>

											<v-col
												cols="6"
												md="2"
												style="margin-top: -15px"
											>
												<s-select-definition
													label="Tipo de Servicio"
													:def="1171"
													v-model="props.item.TypeService"
												/>
											</v-col>

											<v-col
												cols="6"
												md="2"
												style="margin-top: -15px"
											>
												<s-select-definition
													:def="1174"
													label="Procedencia"
													v-model="props.item.TypeOrigin"
												/>
											</v-col>

											<v-col
												cols="6"
												md="2"
												style="margin-top: -15px"
											>
												<s-select-definition
													:def="1172"
													label="Tipo Cultivo"
													v-model="objLot.TypeCrop"
												/>
											</v-col>
											<v-col
												cols="6"
												md="2"
												style="margin-top: -15px"
											>
												<s-select-definition
													:def="1173"
													label="Cultivo"
													v-model="objLot.TypeCultive"
													readonly
												/>
											</v-col>

											<v-col
												cols="6"
												md="2"
												style="margin-top: -15px"
											>
												<s-select-variety
													:cultiveID="objLot.TypeCultive"
													label="Variedad"
													full
													:text="props.item.VrtName"
													:value="objLot.VrtID"
													v-model="objLot.VrtID"
													readonly
												/>
											</v-col>

											<v-col
												cols="6"
												md="2"
												style="margin-top: -15px"
											>
												<!-- <s-text
													label="Tipo Carga"
													v-model="objLot.TypeChargeName"
													readonly
												></s-text> -->
												<s-select-definition
													:def="1231"
													label="Tipo Carga"
													v-model="objLot.TypeCharge"
													readonly
												/>
											</v-col>

											<v-col
												cols="12"
												md="4"
												style="margin-top: -15px"
											>
												<v-text-field
													label="Direccion: "
													v-model="objLot.FagAddress"
													readonly
												></v-text-field>
											</v-col>

											<v-col
												cols="6"
												md="4"
												style="margin-top: -15px"
											>
												<v-text-field
													label="Zona: "
													v-model="objLot.ZonIDText"
													readonly
												></v-text-field>
											</v-col>

											<v-col
												cols="6"
												md="4"
												style="margin-top: -15px"
											>
												<v-text-field
													label="Sub Zona: "
													v-model="objLot.SzoIDText"
													readonly
												></v-text-field>
											</v-col>
										</v-row>
									</v-card-actions>
								</v-card>
							</div>
							<!-- Fin Fundo/User -->

							<!-- Inicio Vehicle -->
							<div class="col-lg-12 col-md-12" style="margin-top: -20px">
								<v-col cols="12" md="12">
									<v-card outlined rounded elevation="2">
										<v-card-text>
											<b>Datos de Vehiculo</b>
										</v-card-text>
										<!-- <v-card-actions> -->
										<v-row>
											<v-col
												cols="12"
												md="12"
												style="margin-top: 0px"
											>
												<v-data-table
													item-key="RcsTptID"
													dense
													:items="itemsVehicle"
													:headers="headersVehicle"
													v-model="selectedVehicle"
													:items-per-page="-1"
													@click:row="rowClickVehicle"
													hide-default-footer
													disable-sort
												>
													<template
														v-slot:item.TptEnrollment="props"
													>
														<s-select-transport
															:value="props.item.TptEnrollment"
															full
															v-model="props.item.TptEnrollment"
														>
														</s-select-transport>
													</template>
													<template v-slot:item.TptDriver="props">
														<s-select-driver
															clearable
															full
															:value="props.item.TptDriver"
															v-model="props.item.TptDriver"
														>
														</s-select-driver>
													</template>

													<template
														v-slot:item.TypeLoadName="props"
													>
														<s-select-definition
															:def="1229"
															v-model="props.item.TypeLoad"
														/>
													</template>
												</v-data-table>
											</v-col>
										</v-row>
										<!-- </v-card-actions> -->
									</v-card>
								</v-col>
							</div>
							<!-- Fin Vehicle -->
						</v-row>

						<!-- Fin Fundo || Vehiculos  -->

						<!-- Inicio Capataz -->
						<v-row>
							<div class="col-lg-12 col-md-12">
								<v-col cols="12" md="12">
									<v-card outlined rounded elevation="2">
										<v-card-text>
											<b>Datos de Capataz</b>
										</v-card-text>

										<!-- <v-card-actions> -->
										<v-row>
											<v-col cols="12">
												<v-data-table
													item-key="RcsGngID"
													dense
													:items="itemsGang"
													:headers="headersGang"
													v-model="selectedGang"
													:items-per-page="-1"
													@click:row="rowClickGang"
													hide-default-footer
													disable-sort
												>
													<template
														v-slot:item.FrmQuantityReal="props"
													>
														<s-text
															v-model="
																props.item.FrmQuantityReal"
															number
														></s-text>
													</template>

													<template v-slot:item.FrmQuantityHarvester="props">
														<s-text
															v-model="props.item.FrmQuantityHarvester"
															number
														></s-text>
													</template>

													<template v-slot:item.TypeChargeName="props">
														<s-text
														v-model="props.item.TypeChargeName"
														readonly 
														></s-text>
													</template>

													<template v-slot:item.FrmName="props">
														<s-select-foreman
															clearable
															full
															:value="props.item.FrmID"
															v-model="props.item.FrmID"
														></s-select-foreman>
													</template>

													<template v-slot:item.FltName="props">
														<!-- v-if="objLot.FltID" :fkFltID="objLot.FltID"-->
														<s-select-lot
															
															:fkFltID="selected.TypeShip == 3 ? 0 : objLot.FltID"
															:PrdID="objLot.PrdID"
															v-model="props.item.FltID"
															clearable
															full
															:FltName="'FltNameOne'"
														/>
													</template>
												</v-data-table
											></v-col>
										</v-row>
										<!-- </v-card-actions> -->
									</v-card>
								</v-col>
							</div>
						</v-row>
						<!-- Fin Capataz -->

						<!-- Inicio Documentos || Datos Lote Recepcion  -->
						<v-row style="margin: auto">
							<!--Inicio datos Lote -->
							<v-col cols="12" lg="4" md="4">
								<v-card flat outlined>
									<v-card-text>
										<b>Lote de Recepcion</b>
									</v-card-text>

									<v-card-actions>
										<v-row>
											<v-col
												cols="12"
												md="12"
												style="margin-top: 0px"
											>
												<s-select-definition
													clearable
													label="Zona Ubicacion"
													v-model="props.item.TypeZonLocation"
													:def="1253"
												/>
											</v-col>
											<!-- <v-col cols="6" md="6" style="margin-top: 0px">
												<s-select-definition
													clearable
													label="Calidad"
													v-model="
														props.item.TypeQualityExportation
													"
													:def="1254"
												/>
											</v-col> -->
											<v-col
												cols="6"
												md="6"
												style="margin-top: -10px"
											>
												<s-text
													label="Peso Neto Recepción"
													v-model="props.item.WbbNetWeight"
													decimal
													readonly
												/>
											</v-col>

											<v-col
												cols="6"
												md="6"
												style="margin-top: -10px"
											>
												<s-select-definition
													clearable
													label="Alerta"
													v-model="props.item.TypeAlert"
													:def="1312"
												/>
											</v-col>
											<v-col
												cols="12"
												md="12"
												style="margin-top: -10px"
											>
											<!--samir-->
												<s-date
													label="Fecha Guia"
													v-model="props.item.GuideDate"
													 
												/>
											</v-col>
										</v-row>
									</v-card-actions>
								</v-card>
							</v-col>
							<!--Fin datos Lote -->

							<!--Inicio Documentos -->
							<v-col cols="12" lg="8" md="8">
								<v-card flat outlined>
									<v-card-text>
										<b>Documentos || </b>
										<span
											style="
												margin-left: 2%;
												color: red;
												font-size: 11px;
												position: absolute;
											"
										>
											<b>Campos Obligatorios (*)</b>
										</span>
									</v-card-text>

									<v-card-actions>
										<v-row>
											<v-col cols="6" :md="selected.FrzID == 0 && selected.TypeShip !== 4 ? 3: 6" style="margin-top: 0px">
												<s-text
													:label="selected.FrzID == 0 && selected.TypeShip !== 4  ? 'Nro. Guia Sunshine' : selected.FrzID > 0 && selected.TypeShip !== 4 ? 'Nro. Guia de Cosecha' : 'N° Guia interna'"
													type="text"
													v-model="props.item.RcfNumberHarvestGuide"
													:mask="maskFormatGuideHarvested"
												>
													<!-- <template v-slot:append v-if="objLot.TypeCultive == 1">
														<i
															style="color: red; font-size: 9px"
															class="fa fa-asterisk"
														></i>
													</template> -->
												</s-text>
											</v-col>

											<v-col cols="6" :md="selected.FrzID == 0 && selected.TypeShip !== 4 ? 3: 6" style="margin-top: 0px" v-if="selected.FrzID == 0 && selected.TypeShip !== 4 || selected.FrzID > 0 && selected.TypeShip !== 4">
												<s-text
													label="Nro. Guia de Remision"
													type="text"
													v-model="
														props.item.RcfNumberReferralGuide
													"
													:mask="maskFormatGuideRemission"
												>
													<template v-slot:append>
														<i
															style="color: red; font-size: 9px"
															class="fa fa-asterisk"
														></i>
													</template>
												</s-text>
											</v-col>

											<v-col cols="6" md="3" style="margin-top: 0px" v-if="selected.FrzID == 0 && selected.TypeShip !== 4">
												<s-text
													label="Precinto Sunshine"
													type="text"
													v-model="props.item.RcfSunshineSeal"
												>
												</s-text>
											</v-col>

											<v-col cols="6" md="3" style="margin-top: 0px" v-if="selected.FrzID == 0 && selected.TypeShip !== 4">
												<s-text
													label="Precinto Senasa"
													type="text"
													v-model="props.item.RcfSenasaSeal"
												/>
											</v-col>

											<v-col
												cols="6"
												md="3"
												style="margin-top: -10px"
												v-if="selected.FrzID == 0 && selected.TypeShip !== 4"
											>
												<s-text
													label="N.G.R Transportista"
													type="text"
													v-model="
														props.item.RcfNumberFerralGuideDriver
													"
													
												>
												</s-text>
											</v-col>
<!-- :mask="'###-###############'" -->
											<v-col
												cols="6"
												md="3"
												style="margin-top: -10px"
												v-if="selected.FrzID == 0 && selected.TypeShip !== 4"
											>
												<s-text
													label="N.G.R-Materiales"
													type="text"
													v-model="
														props.item
															.RcfNumberFerralGuideMaterials
													"
													:mask="'###-###############'"
												/>
											</v-col>

											<v-col
												cols="6"
												md="3"
												style="margin-top: -10px"
												v-if="selected.FrzID == 0 && selected.TypeShip !== 4"
											>
												<s-text
													label="N.G.R Trans-Materiales"
													type="text"
													v-model="
														props.item
															.RcfNumberFerralGuideDriverMaterials
													"
													
												/>
											</v-col>
<!-- :mask="'###-###############'" -->
											<v-col
												cols="6"
												:md="selected.FrzID == 0 && selected.TypeShip !== 4 ? 3 : 6"
												:style="selected.FrzID == 0 && selected.TypeShip !== 4 ? 'margin-top: -10px' : ''"
											>
												<s-text
													label="Peso Guia"
													decimal
													v-model="props.item.RcfWeightGuide"
													:readonly="selected.FrzID == 0 && selected.TypeShip == 4 ? true : false"
												>
												</s-text>
											</v-col>
											<v-col
												cols="6"
												:md="selected.FrzID == 0 && selected.TypeShip !== 4 ? 3 : 6"
												:style="selected.FrzID == 0 && selected.TypeShip !== 4 ? 'margin-top: -10px' : ''"
											>
												<s-text
													label="Peso Ticket"
													decimal
													v-model="props.item.RcfWeightTicket"
													:readonly="selected.FrzID == 0 && selected.TypeShip == 4 ? true : false"
												>
												</s-text>
											</v-col>
										</v-row>
									</v-card-actions>
								</v-card>
							</v-col>
							<!--Fin Documentos -->
						</v-row>

						<v-row>
							<div class="col-md-12 col-lg-12" cols="12">
								<v-col cols="12" md="12" style="margin-top: -20px">
									<s-textarea
										style="margin-top: -15px"
										v-model="props.item.RcfObservations"
										label="Observaciones"
									></s-textarea>
								</v-col>
							</div>
						</v-row>
						<!-- Fin Documentos || Datos Lote Recepcion -->
					</v-card>
				</template>

				<template v-slot:RcfState="{ row }">
				 
					<v-chip x-small :color="row.TypeStateColor">
						{{ row.TypeStateName }}
					</v-chip>
				</template>
				<template v-slot:SamplingFrz="{ row }">
				 
					<v-chip x-small :color="row.SamplingFrz == 1 ? 'success' : 'error'">
						{{ row.SamplingFrz == 1 ? 'Muestreado' : 'NO Muestreado' }}
					</v-chip>
				</template>

				<template v-slot:RcfStatusSap="{ row }">
					<v-chip x-small :color="row.RcfStatusSap == 1 ? 'success' :  row.RcfStatusSap == 2 ? 'warning' : 'error'">
						{{ row.RcfStatusSap == 1 ? 'Por Subir' : row.RcfStatusSap == 2 ? 'Subido' : 'Pendiente'  }}
					</v-chip>
				</template>

				<template v-slot:WbbWeightAverage="{ row }">
					
					{{ (row.WbbWeightAverage).toFixed(2) }}
					
				</template>

				<template v-slot:TypeAlertName="{ row }">
					<v-chip x-small :color="row.TypeAlertColor">
						{{ row.TypeAlertName }}
					</v-chip>
				</template>

				<template v-slot:JabasWeightAct="{ row }">
					<v-chip dark x-small :color="row.JabasWeightAct == 0 ? 'light-green' : 'error'">
						{{ row.JabasWeightAct }}
					</v-chip>
				</template>

				<template v-slot:CloseLot="{ row }">
					<v-btn
						v-if="row.RcfState < 4"
						x-small
						:color="row.TypeStateColor"
						rounded
						style="border-radius: 5px; text-transform: none"
						@click="closeLot(row)"
						>{{ "Cerrar" }}
					</v-btn>
				</template>

				<template v-slot:RcfID="{ row }">
				
					{{ row.RcfID }} 
					<!-- - {{row.RcfCode}} -->
				
				</template>
			</s-crud>
		</div>

		<!-- modal para unir lotes de transferencia -->
		<v-dialog
			v-if="dialogJoinLotTransfer"
			v-model="dialogJoinLotTransfer"
			persistent
			width="500"
		>
			<s-toolbar
				label="Unir lotes transferidos"
				close
				@close="dialogJoinLotTransfer = false"
				color="#BAB6B5"
				dark
			></s-toolbar>
			<v-card>
				<v-container>
					<v-row>
						<v-col>
							<!--no-default clearable-->
							<s-select-definition 
								v-if="keyEntryMer == 2"
								label="Provedor" 
								return-object
								v-model="producerTransfer" 
								:def="1454" 
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col> 
							<s-text
								label="N° Guía"
								v-model="lotParentNumberHarvestGuide"
							></s-text>
						</v-col>
						
					</v-row>
					
					<v-row>
						
						<v-col>
							<s-text
								label="Cantidad Jabas"
								v-model="totalJabasLotTransfer"
								number
							></s-text>
						</v-col>
						<v-col>
							<s-text
								label="Peso Total GUIA"
								v-model="totalWeightLotTransfer"
								decimal
							></s-text>
						</v-col>
						<v-col>
							<s-text
								label="Peso Total Neto"
								v-model="totalWeightNetLotTransfer"
								decimal
							></s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								color="primary"
								block
								@click="saveJoinLotTransfer()"
							>
								Guardar
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog
			v-if="dialogReceivedResume"
			v-model="dialogReceivedResume"
			persistent
			width="1000"
		>
			<s-toolbar
				label="Resumen de KG Recepcionados"
				close
				@close="dialogReceivedResume = false"
				color="#BAB6B5"
			></s-toolbar>
			<reception-resume-k-g></reception-resume-k-g>
		</v-dialog>

		<v-dialog
			v-if="openDialogView"
			v-model="openDialogView"
			persistent
			style="display: contents"
			width="70%"
		>
			<s-certification-lot :FltID="FltID" @close="closeCert()" />
		</v-dialog>

		<!-- Ventana para generar el yuliano -->
		<v-dialog
			v-if="openDialogGenerateYuliano"
			v-model="openDialogGenerateYuliano"
			persistent
			style="display: contents"
			width="70%"
		>
			<v-card>
				<v-col cols="12" md="12" style="margin-left: 0px; padding: unset; ">
					<s-toolbar
						label="Generación Yuliano"
						:color="'#BAB6B5'"
						dark
						close
						@close="closeDialogGenerateYuliano()"
						save
						@save="generateYuliano()"
					/>
				</v-col>
				<v-row style="margin: auto">
					<v-col>
						<s-date-time
							label="Fecha"
							v-model="dateyuliano"
						></s-date-time>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>

		<!-- ventana modal de pesos  -->
		<v-dialog
			v-model="dialogWeigh"
			v-if="dialogWeigh"
			fullscreen
			hide-overlay
			persistent
			transition="dialog-bottom-transition"
		>
			<v-card class="m-0 p-0">
				<s-toolbar
					label="Control de Pesado"
					dark
					:color="'#BAB6B5'"
					close
					@close="closeWeighed()"
					class="mb-6"
				></s-toolbar>

				<frz-weighed-by-batch :ship="ship"></frz-weighed-by-batch>
			</v-card>
			
		</v-dialog>
		<!-- modal del muestreo  v-->

		<v-dialog
			v-model="BrixGrade"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
			persistent
			v-if="BrixGrade"
		>

			<v-card class="elevation-0" 
				v-if="itemsampling.TypeCultive == 1 "
			>
				<FrozenBrix
				
				:items="itemsampling"  @close="BrixGrade = false">
				</FrozenBrix>
			</v-card>
			<v-card class="elevation-0"
				v-if="itemsampling.TypeCultive == 3 || itemsampling.TypeCultive == 4"
			>
				<sampling-palta
					:items="itemsampling"  
					@close="BrixGrade = false"
				>
				</sampling-palta>
			</v-card>

		</v-dialog>

		<v-dialog
			v-model="dialogEnterMaquila"
			fullscreen
		>
			<enter-maquila
				:lotReception="selected"
				@closeEnterMaquila="closeEnterMaquila()"
			></enter-maquila>
		</v-dialog>
		<!-- Para merma -->
		<!-- <v-dialog
			width="700px"
			persistent
			v-model="dialogDecrease"
		>
			<decrease 	@closeDialogDecrease="closeDialogDecrease"
			@saveDecrease="saveDecrease"
			v-model="decrease"
			></decrease>
			
		</v-dialog> -->


		<v-dialog
			v-model="dialogLoadSap"
			v-if="dialogLoadSap"
			persistent
			fullscreen
		>
			<v-card class="m-0 p-0">
				<load-sap-old :lotReception="selected" @closeDialogLoadSap="closeDialogLoadSap()"></load-sap-old>
				<!-- <load-sap :lotReception="selected" @closeDialogLoadSap="closeDialogLoadSap()"></load-sap> -->
			</v-card>
		</v-dialog>
	
	</v-container>
</template>

<script>
	//Service
	import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService";
	import _sGenParam from "../../../services/General/ParameterService";
	import _sFrozenSampling from "@/services/FrozenProduction/FrzfrozenSampling";
	import _sReceptionFrozenService from '@/services/FrozenProduction/ReceptionFrozenService.js';
	//Component
	import SSearchLotByCodSenasa from "@/components/HarvestSchedule/sSearchLotByCodSenasa.vue";
	import SSelectLot from "@/components/HarvestSchedule/SSelectLot.vue";
	import Decrease from "@/components/FrozenProduction/Reception/decrease.vue";
	import SSelectTransport from "@/components/HarvestSchedule/SSelectTransport";
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	import SSelectForeman from "@/components/HarvestSchedule/SSelectForeman";
	import SSelectDriver from "@/components/HarvestSchedule/sSelectDriver.vue";
	import AlertSupplies from "@/components/FreshProduction/FreshReception/AlertSupplies.vue";
	import WeighedByBatch from "@/views/FreshProduction/RawMaterialReception/WeighedByBatch";
	import FrzWeighedByBatch from "@/views/FrozenProduction/FrzWeighedByBatch/FrzWeighedByBatch";
	import SCertificationLot from "@/components/TecnicalAssistence/sCertificationLot.vue";
	import BrixFresh from "@/views/FreshProduction/RawMaterialReception/BrixFresh";

	import FrozenBrix from "@/views/FrozenProduction/Reception/FrozenBrix";

	import sSelectTypeCertification from "@/components/Utils/Certification/sSelectTypeCertification.vue";
	import LoadSapOld from "./LoadSapOld.vue";
	import LoadSap from "./LoadSap.vue";

	import SamplingPalta from "@/views/FrozenProduction/Reception/SamplingPalta";
	import ReceptionResumeKG from './ReceptionResumeKG.vue';
	import EnterMaquila from './EnterMaquila.vue';
	

	export default {
		props: {
			title: {
				type: String,
				default: "Recepción Frescos",
			},
			ship: {
				type: Number,
				default: 2,
			},
		},

		components: {
			EnterMaquila,
			SamplingPalta,
			FrozenBrix,
			Decrease,
			SSearchLotByCodSenasa,
			SSelectLot,
			SSelectTransport,
			SSelectVariety,
			SSelectForeman,
			SSelectDriver,
			AlertSupplies,
			WeighedByBatch,
			FrzWeighedByBatch,
			SCertificationLot,
			BrixFresh,
			sSelectTypeCertification,
			LoadSapOld,
			LoadSap,
			ReceptionResumeKG
		},

		data() {
			return {
				producerTransfer: {},
				totalWeightNetLotTransfer: 0,
				lotParentNumberHarvestGuide: "",
				dataPaging: [],
				totalWeightLotTransfer: 0,
				totalJabasLotTransfer: 0,
				dialogJoinLotTransfer: false,
				JoinArray : [],
				dateyuliano: "",
				openDialogGenerateYuliano: false,
				dialogEnterMaquila: false,
				dialogReceivedResume: false,
				dialogDecrease: false,
				decrease: {},
				FltID: 0,
				disabledAll: false,
				selectedGang: [],
				selectedVehicle: [],
				itemsGang: [],
				itemsVehicle: [],
				//listSupplies: [],
				dialogWeigh: false,
				BrixGrade: false,

				itemsampling: {},

				headersGang: [
					{ text: "Sub Lote", value: "FltName", width: 300 },
					{ text: "Capataz", value: "FrmName", width: 350 },
					{
						text: "Cant. Personal.",
						value: "FrmQuantityHarvester",
						width: 100,
					},
					{
						text: "Cant. Jabas Prog.",
						value: "FrmQuantityEstimate",
						width: 100,
					},
					{
						text: "Cant. Jabas Cosechadas",
						value: "FrmQuantityReal",
						width: 100,
					},
					{
						text: "Tipo Carga",
						value: "TypeChargeName",
						width: 100,
					},
				],

				headersVehicle: [
					{ text: "Placa", value: "TptEnrollment", width: 150 },
					{ text: "Chofer", value: "TptDriver", width: 200 },
					{ text: "Tipo de Carga", value: "TypeLoadName", width: 100 },
				],

				filter: {
					cDateInitial: null,
					cDateFin: null,
					/* cTypeServiceID: 1,
					 */
					filterAll: 0,
					TypeDestiny: this.ship,
					TypeState: null,
				},

				config: {
					service: _sReceptionFresh,
					model: {
						RcfID: "ID",
						RcfDateReceptionSurveillance: "date",
						RcfState: "",
						CloseLot: "",
						WbbWeightAverage: 0,
						ViewCert: "",
						genyuliano: "",
						TypeAlertName: "",
						SamplingFrz: 0,
						RcfStatusSap: "",
						JabasWeightAct: "",
						Join: ""
					},
					headers: [
						{ text: "Nro. Lote.", value: "RcfID" },
						{ text: "Unir", value: "Join", width: 70 },
						{ text: "G. Yuliano", value: "genyuliano", width: 70 },
						{ text: "View", value: "ViewCert", width: 70 },
						// { text: "Cerrar", value: "CloseLot", width: 50 },
						{ text: "Estado", value: "RcfState", width: 20 },
						{ text: "Muestreado", value: "SamplingFrz", width: 160, },
						{ text: "Peso Ticket", value: "RcfWeightTicket", width:100},
						{ text: "Peso Guia", value: "RcfWeightGuide", width:100}, 
						{ text: "Peso Neto.", value: "WbbNetWeight", width: 100 },
						{ text: "Guia Cosecha", value: "RcfNumberHarvestGuide", width: 160, },
						{ text: "Estado Sap", value: "RcfStatusSap", width: 120},
						{ text: "Peso Bruto", value: "WbbGrossWeight", width: 100 },
						{ text: "Guia Remision", value: "RcfNumberReferralGuide", width: 160, },
						
						{ text: "Juliano", value: "PrfYuliano", width: 100 },
						/* {
							text: "Guia Cosecha",
							value: "RcfNumberHarvestGuide",
							width: 160,
						},
						{ text: "Peso Bruto", value: "WbbGrossWeight", width: 100 },
						{
							text: "Guia Remision",
							value: "RcfNumberReferralGuide",
							width: 160,
						}, */
						{
							text: "Fec. Recep. Vigilancia.",
							value: "RcfDateReceptionSurveillance",
							width: 140,
						},
						{
							text: "Tipo Servicio",
							value: "TypeServiceName",
							width: 100,
						},
						{ text: "Productor", value: "PrdCardName", width: 260 },
						{ text: "Fundo", value: "FagName", width: 260 },
						{ text: "Lote", value: "FltName", width: 100 },
						{ text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
						{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
						{ text: "Variedad", value: "VrtName", width: 160 },
						{ text: "Cod. Senasa", value: "FltCodSenasa", width: 160 },
						{
							text: "Guia Cosecha",
							value: "RcfNumberHarvestGuide",
							width: 160,
						},
						{ text: "Peso Bruto", value: "WbbGrossWeight", width: 100 },
						{
							text: "Guia Remision",
							value: "RcfNumberReferralGuide",
							width: 160,
						},
						{
							text: "N° Jabas",
							value: "AmountJabasWeigh",
							width: 160,
						},
					

						{
							text: "Muestreado",
							value: "SamplingFrz",
							width: 160,
						},
						{
							text: "Peso Promedio Fruta",
							value: "WbbWeightAverage",
							width: 160,
						},
						// {
						// 	text: "Peso Total Lote",
						// 	value: "WbbNetWeight",
						// 	width: 160,
						// },
						{text: "Estado peso", value: "JabasWeightAct", width: 120},

						{
							text: "Planta Procesdora",
							value: "TypeProcessingPlantName",
							width: 160,
						},
						{ text: "Empacadora", value: "TypeBalerName", width: 160 },
						{
							text: "Zona Ubicacion",
							value: "TypeZonLocationName",
							width: 160,
						},
						{ text: "Alerta", value: "TypeAlertName", width: 100 },
						{
							text: "Observaciones",
							value: "RcfObservations",
							width: 260,
						},
						{
							text: "Tipo de Entrada",
							value: "TypeEntryMerchandiseName",
							width: 260,
						},
						
					],
				},

				selected: {},

				openDialogView: false,
				existsData: false,

				objLot: { PrdText: null, FagName: null, FltName: null },

				maskFormatGuideHarvested: "",
				maskFormatGuideRemission: "",
				xCount: 0,
				dialogLoadSap: false,
				keyEntryMer: 1,
			};
		},

		created() {
			this.loadParameter();
		},

		methods: {
			saveJoinLotTransfer()
			{

				console.log("proveddor ", this.producerTransfer);

				 
				/*Revisamos que todos tengan el mismo lote */
				this.$fun.alert("¿Seguro de unir lotes?", "question")
				.then(r => {
					if(r.value){
						let RcfidJoinArray = this.JoinArray.toString()

						let lotParent = {
							RcfIDs : RcfidJoinArray,
							UsrCreateID : this.$fun.getUserID(),
							RcfWeightParent : this.totalWeightLotTransfer,
							RcfAmountParent : this.totalJabasLotTransfer,
							RcfHarvestGuide : this.lotParentNumberHarvestGuide,
							FltID: this.producerTransfer.DedAbbreviation ,
							FagID: this.producerTransfer.DedHelper ,
							PrdID: this.producerTransfer.DedFormat ,
							TypeJoin: this.keyEntryMer,
						}
						_sReceptionFrozenService
						.savejoinlottransfer(lotParent, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Lotes unidos correctamente", "success");
								this.dialogJoinLotTransfer = false;
								this.$refs.gridReceptionFresh.refresh();
								this.JoinArray = [];
								RcfidJoinArray = "";
							}
						})
					}
				})
			},

			getFullData(data)
			{				
				this.dataPaging = data.data;
			},

			addLotTransfer(item)
			{
				this.totalWeightLotTransfer = 0;
				this.totalJabasLotTransfer = 0;
				this.keyEntryMer = 1;
				this.JoinArray.forEach(element => {
					let itemFound = this.dataPaging.find(el => el.RcfID == element);
					this.totalWeightLotTransfer += itemFound.RcfWeightGuide;
					this.totalWeightNetLotTransfer += itemFound.WbbNetWeight;
					this.totalJabasLotTransfer += itemFound.AmountJabasWeigh;
					console.log("elemte", itemFound)
					if(itemFound.TypeEntryMerchandise == 3){
						this.keyEntryMer = 2;
					}
				});
				console.log('this.totalWeightLotTransfer ', this.totalWeightLotTransfer);
				console.log('this.totalWeightLotTransfer ', this.totalJabasLotTransfer);
			},

			openJoinLotTransfer()
			{
				this.dialogJoinLotTransfer = true;
			},

			cancelLot()
			{
				this.$fun.alert("¿Seguro de cancelar lote?", "question")
				.then(r => {
					if(r.value)
					{
						this.selected.RcfState = 61; //codigo para la generacion de yuliano
						_sReceptionFresh
						.save(this.selected, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Lote cancelado correctamente", "success");
								this.$refs.gridReceptionFresh.refresh();
								return;
							}
						}) 
					}
				})
			},

			closeEnterMaquila()
			{
				this.dialogEnterMaquila = false;
			},

			openEnterMaquila()
			{
				this.dialogEnterMaquila = this.selected;
			},

			downloadDamage() {
				
				console.log('this select', this.selected)
				if(this.selected != null || this.selected != undefined) {
					_sFrozenSampling.samplingRPdf(this.selected, this.$fun.getUserID()).then((r) => {
						this.$fun.downloadFile(
						r.data,
						this.$const.TypeFile.PDF,
						"Muestreo recepcion congelado"
						);
					});

				}

			}, 

			openReceivedResume()
			{
				this.dialogReceivedResume = true;
			},

			openDialogLoadSap()
			{
				this.dialogLoadSap = true;
			},

			closeDialogLoadSap()
			{
				this.dialogLoadSap = false;
				this.$refs.gridReceptionFresh.refresh();
			},

			loadParameter() {
				_sGenParam
					.search(
						{ PrmName: "maskFormatGuideHarvested" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						if (resp.status == 200) {
							this.maskFormatGuideHarvested = resp.data.PrmValue;
						}
					});

				_sGenParam
					.search(
						{ PrmName: "maskFormatGuideRemission" },
						this.$fun.getUserID(),
					)
					.then(resp => {
						if (resp.status == 200) {
							this.maskFormatGuideRemission = resp.data.PrmValue;
						}
					});
			},

			closeBrixGrade(){
				this.BrixGrade = false
				this.$refs.gridReceptionFresh.refresh();
			},

			closeDialogDecrease() {
				this.dialogDecrease = false;
			},

			saveDecrease(item) {
				this.decrease = item;
			},

			rowClickGang: function(item, row) {
			   	this.selectedGang = [item];
			},

			rowClickVehicle: function(item, row) {
				this.selectedVehicle = [item];
			},

			clickGenerateYuliano(val)
			{
				this.openDialogGenerateYuliano = true;
			},

			closeDialogGenerateYuliano()
			{
				this.openDialogGenerateYuliano = false;
			},

			generateYuliano()
			{
				this.selected.RcfDateReception = this.dateyuliano;
				this.selected.RcfState = 60; //codigo para la generacion de yuliano
				_sReceptionFresh
				.save(this.selected, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Yuliano Generado Correctamente", "success");
						return;
					}
				}) 
				console.log('ddd', this.dateyuliano);
			},

			clickView(val) {
				this.FltID = val.FltID;
				this.openDialogView = true;
			},

			closeCert() {
				this.openDialogView = false;
			},

			closeWeighed() {
				this.dialogWeigh = false;
				this.$refs.gridReceptionFresh.refresh();
			},

			WeighMaterial() {
				this.dialogWeigh = true;
			},

			ModalBrix() {
				this.BrixGrade = true;
			},

			rowSelected(item) {
				if (item.length == 0) {
					this.selected = {};
					this.objLot = {};
					this.itemsGang = [];
					this.itemsVehicle = [];
					this.disabledAll = false;
					this.existsData = false;

					//this.hasSuppliesNotRecommended = false;
				} else {
					if (item[0] != undefined) {
						this.objLot = item[0];
						this.selected = item[0];
						
						

						this.existsData = true;

						_sReceptionFresh
							.GetGang(
								{ RcfID: this.selected.RcfID },
								this.$fun.getUserID(),
							)
							.then(r => {
								this.itemsGang = r.data;
							});

						_sReceptionFresh
							.GetTransport(
								{ RcfID: this.selected.RcfID },
								this.$fun.getUserID(),
							)
							.then(r => {
								this.itemsVehicle = r.data;
							});

						this.objLot.PrdText = this.selected.PrdCardName;
						this.objLot.FagName = this.selected.FagName;
						this.objLot.FltName = this.selected.FltName;

						this.objLot.PrdID = this.selected.PrdID;
						this.objLot.FagID = this.selected.FagID;
						this.objLot.FltID = this.selected.FltID;
						this.objLot.FltCodSenasa = this.selected.FltCodSenasa;

						this.objLot.TypeCrop = this.selected.TypeCrop;
						this.objLot.TypeCultive = this.selected.TypeCultive;
						this.objLot.VrtID = this.selected.VrtID;

						this.objLot.FagAddress = this.selected.FagAddres;
						this.objLot.ZonIDText = this.selected.ZonName;
						this.objLot.SzoIDText = this.selected.SzoName;

						if (this.selected.RcfState >= 4) {
							this.disabledAll = true;
						} else {
							this.disabledAll = false;
						}

						
					}
				}


				this.itemsampling = this.selected;

				//this.initializeFrozenSAP();
			},

			/* initializeFrozenSAP()
			{
				
				console.log('traer datos frozen', this.lotReception)

				_sReceptionFrozenService
				.searchbyid(this.selected, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						if(resp.data != null)
						{
							this.selected.Issue = resp.data.RfsIssue;
							this.selected.IgvCheck = resp.data.RfsIgv;
							this.ObjTypeEntryDocument.DedHelper = resp.data.RfsSerie;
							this.ObjTypeEntryDocument.DedValue = resp.data.TypeEntryDocument;
							this.weigthSelectedReal = resp.data.RfsWeigth;
							this.selected.TypeWeight = resp.data.TypeWeight;
							this.selected.DateDocument = resp.data.RfsDocumentDate;
							this.selected.TypeEntryDocument = resp.data.TypeEntryDocument;
							this.selected.Serie = resp.data.Serie;
							this.selected.RfsID = resp.data.RfsID;
							//this.lotReception.Issue = resp.data.Issue;
						}
					}
				})
			}, */

			isValidatedForeman() {
				let isValidated = true;
				//Validar Grilla Capataces
				if (this.itemsGang.length == 0) {
					this.$fun.alert(
						"La Recepcion: " +
							this.selected.RcfID +
							", No cuenta con Capataces asignados.",
						"warning",
					);
					isValidated = false;
					return;
				}

				this.itemsGang.forEach(element => {
					console.log("element", element);

					/* if (element.FrmID == null) {
						this.$fun.alert(
							"Uno de los registro no se ha cargado Capataz",
							"warning",
						);
						isValidated = false;
						return;
					} */

					if (element.FltID == null) {
						this.$fun.alert(
							"Uno de los registro no se ha cargado Sub-Lote",
							"warning",
						);
						isValidated = false;
						return;
					}

					/* if (element.FrmQuantityHarvester <= 0) {
						this.$fun.alert(
							"Cantidad de Cosechadores no puede ser menor o igual a 0, para Capataz: " +
								element.FrmName,
							"warning",
						);
						isValidated = false;
						return;
					} */

					if (element.FrmQuantityReal <= 0) {
						this.$fun.alert(
							"Cantidad de Fruta no puede ser menor o igual a 0, para Capataz: " +
								element.FrmName,
							"warning",
						);
						isValidated = false;
						return;
					}
				});

				return isValidated;
			},

			isValidatedVehicle() {
				let isValidated = true;

				if(this.selected.FrzID == 0){

				
					//Validar Grilla Vehiculos
					
					if (this.itemsVehicle.length == 0) {
						this.$fun.alert(
							"La Recepcion: " +
								this.selected.RcfID +
								", No cuenta con Vehiculos asignados.",
							"warning",
						);
						isValidated = false;
						return;
					}

				this.itemsVehicle.forEach(element => {
					if (element.TptEnrollment == null) {
						this.$fun.alert(
							"Necesita especificar un Vehiculo",
							"warning",
						);
						isValidated = false;
						return;
					}

					if (element.TptDriver == null) {
						this.$fun.alert("Necesita especificar un Chofer", "warning");
						isValidated = false;
						return;
					}

					if (element.TypeLoad == null) {
						this.$fun.alert(
							"Necesita especificar tipo de carga",
							"warning",
						);
						isValidated = false;
						return;
					}
				});
				}
				return isValidated;
			},

			isValidatedDocuments(val) {


				let isValidated = true;

				if(this.objLot.TypeCultive == 1 && this.objLot.TypeDestiny == 1){
					if (
						val.RcfNumberHarvestGuide == "" ||
						val.RcfNumberHarvestGuide == null
					) {
						this.$fun.alert("Ingrese Numero Guia de Cosecha", "warning");
						isValidated = false;
						return;
					}

					/* if (
						val.RcfNumberFerralGuideDriver == "" ||
						val.RcfNumberFerralGuideDriver == null
					) {
						this.$fun.alert(
							"Ingrese numero Guia remision de Transportista",
							"warning",
						);
						isValidated = false;
						return;
					} */
				}
				

				if(this.selected.FrzID == 0 ){

				
					if (
						val.RcfNumberReferralGuide == "" ||
						val.RcfNumberReferralGuide == null
					) {
						this.$fun.alert("Ingrese numero Guia de Remision", "warning");
						isValidated = false;
						return;
					}
				}

				return isValidated;
			},

			isValidatedHeader(val) {
				let isValidated = true;

				if (val.RcfID == 0) {
					this.$fun.alert("No se ha seleccionado registro", "warning");
					isValidated = false;
					return;
				}

				if (
					this.objLot.PrdText == null ||
					this.objLot.PrdText == undefined
				) {
					this.$fun.alert("No se ha seleccionado Productor", "warning");
					isValidated = false;
					return;
				}

				if (
					this.objLot.FagName == null ||
					this.objLot.FagName == undefined
				) {
					this.$fun.alert("No se ha seleccionado Fundo", "warning");
					isValidated = false;
					return;
				}

				if (
					this.objLot.FltName == null ||
					this.objLot.FltName == undefined
				) {
					this.$fun.alert("No se ha seleccionado Lote", "warning");
					isValidated = false;
					return;
				}

				if (
					this.objLot.TypeCultive == undefined ||
					this.objLot.TypeCultive == null
				) {
					this.$fun.alert("No se ha seleccionado Cultivo", "warning");
					isValidated = false;
					return;
				}

				if (
					this.objLot.TypeCrop == undefined ||
					this.objLot.TypeCrop == null
				) {
					this.$fun.alert("No se ha seleccionado Tipo Cultivo", "warning");
					isValidated = false;
					return;
				}

				return isValidated;
			},

			closeLot(items) {
				//console.log("cerrar lote", items);
				if (items.RcfState >= 4) {
					this.$fun.alert(
						"Lote se encuentra ==> " + items.TypeStateName,
						"warning",
					);
					return;
				}

				this.$fun
					.alert("Esta seguro de Cerrar el Lote ? ", "question")
					.then(val => {
						if (val.value) {
							items.RcfState = 4;
							items.UsrCreateID = this.$fun.getUserID();

							_sReceptionFresh
								.save(items, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert(
											"Lote cerrado correctamente",
											"success",
										);
										this.$refs.gridReceptionFresh.refresh();
									}
								});
							this.$refs.gridReceptionFresh.refresh();
						}
					});
			},

			save(val) {
				if (val.RcfState >= 4) {
					this.$fun.alert(
						"Lote se encuentra ==> " +
							val.TypeStateName +
							", No se puede modifcar.",
						"warning",
					);
					return;
				}

				if (!this.isValidatedHeader(val)) {
					return;
				}
				if (!this.isValidatedForeman()) {
					return;
				}
				if (!this.isValidatedVehicle()) {
					return;
				}
				if (!this.isValidatedDocuments(val)) {
					return;
				}

				//samir
				if (
						val.GuideDate == "" ||
						val.GuideDate == null
				) {
					this.$fun.alert("Ingrese Fecha Guia", "warning");
					isValidated = false;
					return;
				}

				this.xCount = 0;
				val.SecStatus = 1;
				val.UsrCreateID = this.$fun.getUserID(); //Obtenemos el ID del Usuario
//				val.RcfState = 1;
				val.RcfDateReception = this.$fun.getDate();

				val.PrdID = this.objLot.PrdID;
				val.FagID = this.objLot.FagID;
				val.FltID = this.objLot.FltID;

				val.TypeCrop = this.objLot.TypeCrop;
				val.TypeCultive = this.objLot.TypeCultive;
				val.VrtID = this.objLot.VrtID;

				//Verificamos que Planta Procesadora es Sunshine, Establecemos Empacadora en NULL
				if (val.TypeProcessingPlant == 1) {
					val.TypeBaler = null;
				}

				//Agregamos el RcsID a Capataces
				this.itemsGang.forEach(element => {
					element.RcfID = val.RcfID;
					element.SecStatus = 1;
					element.UsrCreateID = this.$fun.getUserID();
				});

				//Agregamos el RcsID a Vehiculos
				this.itemsVehicle.forEach(element => {
					element.RcfID = val.RcfID;
					element.SecStatus = 1;
					element.UsrCreateID = this.$fun.getUserID();

					if (element.TypeLoad == 1) {
						this.xCount = this.xCount + 1;
					}
				});

				val.itemsGang = this.itemsGang;
				val.itemsVehicle = this.itemsVehicle;

				if(this.selected.FrzID == 0){
					if (this.xCount > 1) {
						this.$fun.alert(
							"Error... El Lote de Producción solo puede tener 01 vehiculo para transporte de Fruta",
							"warning",
						);
						return;
					}

					if (this.xCount == 0) {
						this.$fun.alert(
							"Error... El Lote de Producción no contiene vehiculo para transporte de Fruta",
							"warning",
						);
						return;
					}
				}

				console.log("Guardar", val);

				val.save();
			},

			openAudio() {
				var audio = new Audio(
					require("../../../../../sound/nohaynadie.mp3"),
				);
				audio.play();
			},

			searchSenasa(item) {
				if (item) this.objLot = item;
				else this.objLot = {};
			},

			editEvent() {
				if (this.existsData == false) {
					this.$fun.alert("No se ha seleccionado registro", "warning");
				}
			},
		},
	};
</script>
